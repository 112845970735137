import React from 'react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { themeToMuiTheme } from './mui-theme-helper';

/*
Restricting theme settings so it's easier to switch UI libraries.
*/

export enum ThemeType {
  Light = 'LIGHT',
  Dark = 'DARK'
}

interface ThemeColors {
  main: string;
  danger: string;
  backgroundColor: string;
  // textColor: string;
}

export interface Theme {
  light: ThemeColors;
  dark: ThemeColors;
  type: ThemeType;
}

// unsafe at start
const ThemeContext = React.createContext({
  theme: {} as Theme,
  setTheme: (() => { /* do nothing */ }) as (theme: Theme) => void
});

export const useTheme: () => { theme: Theme, setTheme: (theme: Theme) => void } = () => {
  return React.useContext(ThemeContext);
};

export const useCurrentTheme = () => {
  const { theme } = useTheme();
  return { type: theme.type, colors: theme.type === ThemeType.Light ? theme.light : theme.dark };
};

export const ThemeProvider = (props: React.PropsWithChildren<{ theme: Theme }>) => {
  const [theme, setTheme] = React.useState(props.theme);
  const muiTheme = themeToMuiTheme(theme);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
