import { useState, cloneElement } from 'react';
import { ConfirmModal } from '@telemetris/ui-components';


export interface ConfirmButtonProps {
  title?: string;
  message?: string;
  yesLabel: string;
  noLabel: string;
  onConfirm: () => void;
  children: JSX.Element;
}

export function ConfirmButton(props: ConfirmButtonProps) {
  const { onConfirm, ...modalProps } = props;
  const [open, setOpen] = useState(false);
  const onClose = (yesClicked: boolean) => {
    setOpen(false);
    if (yesClicked) {
      props.onConfirm();
    }
  };
  const onClick = () => setOpen(true);

  return <div>
    { cloneElement(props.children, { onClick }) }
    <ConfirmModal
      {...modalProps}
      open={open}
      onClose={onClose}
    />
  </div>;
}

export default ConfirmButton;
