import { IsEmail, IsEnum, IsOptional, IsString, IsUUID, Length, IsBoolean } from 'class-validator';
import { Buoy, transformFromBuoyDto } from './buoy.api';
import { authService } from './auth.api';
import { BuoyFilter } from './buoy.api';
import { createFilterBuilder, Filter } from './reusable/filters';
import { RestCrudApi, RestOneToMany } from './reusable/generic-rest-crud';
import { RegionFilter, Region } from './region.api';

export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  RegionAdmin = 'REGION_ADMIN', // can only see stuff from regions assigned to
  ViewOnlyUser = 'VIEW_ONLY_USER' // can only see buoys assigned to
}

export interface User {
  id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  role: UserRole;
  receiveMonthlyDigest: boolean;
  receiveAlarmEmails: boolean;
}

export class UserCreate {
	@IsEmail()
	email!: string;
	
	@IsString()
	@Length(5)
	password!: string;

	@IsEnum(UserRole)
	role!: UserRole;

	@IsBoolean()
	receiveMonthlyDigest?: boolean;  // TODO: optional because Yup wouldn't have it any other way

	@IsBoolean()
	receiveAlarmEmails?: boolean;  // TODO: optional because Yup wouldn't have it any other way

	@IsUUID(undefined, { each: true })
	@IsOptional()
	regionIds?: string[];

	@IsUUID(undefined, { each: true })
	@IsOptional()
	viewOnlyBuoyIds?: string[];
}

export class UserUpdate {
	@IsUUID()
	id!: string;

	@IsEmail()
	email?: string;
	
	@IsString()
	@Length(5)
	password?: string;

	@IsEnum(UserRole)
	role?: UserRole;

	@IsBoolean()
	receiveMonthlyDigest?: boolean;

	@IsBoolean()
	receiveAlarmEmails?: boolean;

	@IsUUID(undefined, { each: true })
	@IsOptional()
	regionIds?: string[];

	@IsUUID(undefined, { each: true })
	@IsOptional()
	viewOnlyBuoyIds?: string[];
}


declare const USER_FILTER_TAG: unique symbol;
/**
 * this does that
 */
export type UserFilter = Filter & { readonly [USER_FILTER_TAG]: 'USER_FILTER' }

export enum UserFilterName {
	Id = 'id',
	Email = 'email',
	Role = 'role',
	ReceiveMonthlyDigest = 'receiveMonthlyDigest',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt',
	RegionId = 'regionId',
	// many to many
	BuoyId = 'buoyId'
}

export const Id = createFilterBuilder<string, UserFilter>(UserFilterName.Id);
export const Email = createFilterBuilder<string, UserFilter>(UserFilterName.Email);
export const UserRoleType = createFilterBuilder<string, UserFilter>(UserFilterName.Role);
export const ReceiveMonthlyDigest = createFilterBuilder<boolean, UserFilter>(UserFilterName.ReceiveMonthlyDigest);
export const CreatedAt = createFilterBuilder<Date, UserFilter>(UserFilterName.CreatedAt);
export const UpdatedAt = createFilterBuilder<Date, UserFilter>(UserFilterName.UpdatedAt);
export const RegionId = createFilterBuilder<string, UserFilter>(UserFilterName.RegionId);
export const BuoyId = createFilterBuilder<string, UserFilter>(UserFilterName.BuoyId);

export const userApi = new RestCrudApi<UserFilter, User, UserCreate, UserUpdate>('/api/users2', authService);
export const userViewOnlyBuoysApi = new RestOneToMany<BuoyFilter, Buoy>('/api/users', 'view-only-buoys', authService, transformFromBuoyDto);
export const userRegionsApi = new RestOneToMany<RegionFilter, Region>('/api/users', 'regions', authService);
