import { darken, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

export interface ToggleButtonOption<T> {
  value: T;
  label: string;
}

export interface ToggleButtonProps<T> {
	value: T;
	onChange: (value: T) => void;
	options: ToggleButtonOption<T>[];
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

const useStyles = makeStyles<Theme, { selectedIndex: number, numberOfOptions: number, disabled: boolean }>(theme => {
  const backgroundColor = darken(theme.palette.background.paper, 0.1);
  return {
    outerWrapper: {
      width: '200px',
      height: '30px',
    },
    wrapper: {
      'display': 'flex',
      'border-radius': '20px',
      'border': 'none',
      'background': backgroundColor,
      'width': '100%',
      'height': '100%',
      'position': 'relative',
      'opacity': props => props.disabled ? 0.7 : 1,
      '&::before': {
          'top': '50%',
          'left': props => `${(props.selectedIndex / props.numberOfOptions) * 100}%`,
          'content': `''`,
          'position': 'absolute',
          'height': 'calc(100% - 2*1px)',
          'width': props => `calc(100% / ${props.numberOfOptions} - 2*1px)`,
          'border-radius': '18px',
          'border': `2px solid ${backgroundColor}`,
          'background': theme.palette.primary.main,
          'transition': 'left 0.1s linear, background 0.1s linear',
          'transform': 'translateY(-50%)',
          'z-index': 1,
          'box-sizing': 'content-box',
      }
    },
    option: props => ({
      'display': 'flex',
      'align-items': 'center',
      'height': '100%',
      'width': `${100 / props.numberOfOptions}%`,
      'border-radius': '18px',
    }),
    optionLabel: {
      'display': 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'width': '100%',
      'height': '100%',
      'z-index': 2,
      'transition': 'color 0.1s linear',
      'cursor': props => props.disabled ? 'not-allowed' : 'pointer',
      'color': theme.palette.text.primary,
    },
    optionLabelSelected: {
      'color': theme.palette.primary.contrastText,
    },
    input: {
      'width': 0,
      'height': 0,
      'opacity': 0,
      'z-index': -1,
      'position': 'absolute',
      'pointer-events': 'none',
    }
  };
});

export function ToggleButton<T>(props: ToggleButtonProps<T>) {
  const selectedIndex = props.options.findIndex(option => option.value === props.value);
  const classes = useStyles({ numberOfOptions: props.options.length, selectedIndex, disabled: !!props.disabled });
  return (
    <div className={clsx(classes.outerWrapper,props.className)} style={props.style}>
      <div className={classes.wrapper}>
        {props.options.map((option, index) => (
          <div className={classes.option} key={index}>
            <label className={clsx(classes.optionLabel, selectedIndex === index && classes.optionLabelSelected)}>
              <input className={classes.input}
                    type='radio'
                    id={`toggle-button-${index}`}
                    onChange={() => props.onChange(props.options[index].value)}
                    checked={selectedIndex === index}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ToggleButton;

