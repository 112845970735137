import { BuoyMsgType } from '../../../api/buoy/buoy-downlink';

export const BuoyMsgTypeTranslation: Record<BuoyMsgType, string> = {
	[BuoyMsgType.ChangePassword]: 'Promjena lozinke',
	[BuoyMsgType.Configuration]: 'Konfiguracija',
	[BuoyMsgType.GeoTrigger]: 'Geolociranje',
	[BuoyMsgType.GpsConfiguration]: 'Konfiguracija GPS-a',
	[BuoyMsgType.PowerOff]: 'Gašenje uređaja',
};

export const BuoyChangePasswordMsgTranslation: Record<string, string> = {
	type: 'Tip poruke',
	password: 'Lozinka',
};

export const BuoyConfigurationMsgTranslation = {
	type: 'Tip poruke',
	operatingMode: 'Način rada',
	msgIntervalPhaseInit: 'Inicijalni interval poruka',
	msgIntervalPhase1: 'Faza 1 - interval poruka',
	msgIntervalPhase2: 'Faza 2 - interval poruka',
	msgIntervalPhase3: 'Faza 3 - interval poruka',
	msgIntervalPhase4: 'Faza 4 - interval poruka',
	msgIntervalPhase5: 'Faza 5 - interval poruka',
	msgIntervalPhaseStandby: 'Idle - interval poruka',
	msgIntervalIdle: 'Max broj neposlanih poruka',
	manualTriggerOn: 'Manualni način rada',
	accAngleThreshold: 'Kutni prag akcelerometra',
	accTiltTimeout: 'Vrijeme detekcije kutnog praga',
	maxUnsentLogs: 'Max broj neposlanih log poruka'
};

export const BuoyConfigurationMsg_OperatingMode_Translation = {
	manual: 'Ručni',
	tilt_detection: 'Detekcija pokreta',
};

export const BuoyGeoTriggerMsgTranslation: Record<string, string> = {
	type: 'Tip poruke',
};

export const BuoyGpsConfigurationMsgTranslation = {
	type: 'Tip poruke',
	operatingMode: 'Algoritam rada GPS-a',
	gpsNavigationMode: 'Način rada GPS-a',
	lockTimeout: 'Max trajanje geolociranja ',
	targetHDOP: 'Ciljani HDOP',
	targetHEPE: 'Ciljani HEPE',
	extraTime: 'Ekstra vrijeme',
	maxDistance: 'Maksimalna udaljenost',
	distanceRepetitions: 'Broj ponavljanja',
};

export const BuoyGpsConfigurationMsg_GpsNavigationMode_Translation = {
	normal: 'Normalni',
	fitness: 'Fitness',
	aviation: 'Avijacija',
	baloon: 'Balon',
	stationary: 'Stacionarni',
};

export const BuoyGpsConfigurationMsg_OperatingMode_Translation = {
	max_hdop: 'Max HDOP',
	max_hepe: 'Max HEPE',
	no_movement: 'Bez značajnog odstupanja',
};

export const BuoyPowerOffMsgTranslation: Record<string, string> = {
	type: 'Tip poruke',
	factoryReset: 'Factory reset',
};

export const BuoyMsgTypePropTranslation: Record<string, Record<string, string>> = {
	[BuoyMsgType.ChangePassword]: BuoyChangePasswordMsgTranslation,
	[BuoyMsgType.Configuration]: BuoyConfigurationMsgTranslation,
	[BuoyMsgType.GeoTrigger]: BuoyGeoTriggerMsgTranslation,
	[BuoyMsgType.GpsConfiguration]: BuoyGpsConfigurationMsgTranslation,
	[BuoyMsgType.PowerOff]: BuoyPowerOffMsgTranslation,
};

