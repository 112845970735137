import { subHours } from 'date-fns/esm';
import { Buoy } from '../../../api/buoy.api';
import { PointOfInterest } from '../../../api/point-of-interest.api';

export enum MapActionType {
	SelectBuoy = 'SELECT-BUOY',
	SelectBuoys = 'SELECT-BUOYS',
	ToggleSelectBuoy = 'TOGGLE-SELECT-BUOY',
	ClearBuoySelection = 'CLEAR-SELECTION',
	SetBuoysWithoutHistory = 'SET-BUOYS-WITHOUT-HISTORY',
	SetBuoys = 'SET-BUOYS',
	SelectFishingZone = 'SELECT-FISHING-ZONE',
	SelectBreedingGround = 'SELECT-BREEDING-GROUND',
	SetHistoryPeriodOption = 'SET-HISTORY-TIME-INTERVAL',
	SetFishingZonesVisibility = 'SET-FISHING-ZONES-VISIBILITY',
	SetBreedingGroundsVisibility = 'SET-BREEDING-GROUNDS-VISIBILITY',
	SetPointsOfInterest = 'SET-POINTS-OF-INTEREST',
	SetCurrentMapTime = 'SET-CURRENT-MAP-TIME',
}

export type HistoryPeriodOption =
	| { type: 'LAST_N_HOURS', hours: number }
	| { type: 'TIME_RANGE', from: Date, to: Date }

export const toTimeRange = (opt: HistoryPeriodOption, toTime?: Date) => {
	if (opt.type === 'LAST_N_HOURS') {
		const now = toTime || new Date();
		return { from: subHours(now, opt.hours), to: now };
	}
	return { from: opt.from, to: opt.to };
};

export type MapAction =
	| { type: MapActionType.SetBuoysWithoutHistory, buoys: Buoy[] }
	| { type: MapActionType.SetBuoys, buoys: Buoy[] }
	| { type: MapActionType.SelectBuoys, buoyIds: string[] }
	| { type: MapActionType.SelectBuoy, buoyId: string }
	| { type: MapActionType.ToggleSelectBuoy; buoyId: string }
	| { type: MapActionType.ClearBuoySelection }
	| { type: MapActionType.SelectFishingZone, fishingZoneId: string, fishingZone: any }
	| { type: MapActionType.SelectBreedingGround, breedingGround: any }
	| { type: MapActionType.SetHistoryPeriodOption, historyPeriodOption: HistoryPeriodOption }
	| { type: MapActionType.SetFishingZonesVisibility, visible: boolean }
	| { type: MapActionType.SetBreedingGroundsVisibility, visible: boolean }
	| { type: MapActionType.SetPointsOfInterest, pointsOfInterest: PointOfInterest[] }
	| { type: MapActionType.SetCurrentMapTime, currentMapTime?: Date }

export interface MapState {
	currentMapTime?: Date;
	buoys: Record<string, Buoy>;
	selectedBuoyIds: string[];
	buoysWithoutHistory: Buoy[];
	fishingZoneId?: string;
	fishingZone?: any;
	breedingGround?: any;
	fishingZonesVisible: boolean;
	breedingGroundsVisible: boolean;
	historyPeriodOption: HistoryPeriodOption;
	pointsOfInterest: PointOfInterest[];
}

export const getInitialState = (): MapState => ({
	buoysWithoutHistory: [],
	// buoys: [],
	buoys: {},
	selectedBuoyIds: [],
	historyPeriodOption: {
		type: 'LAST_N_HOURS',
		hours: 24
	},
	fishingZonesVisible: true,
	breedingGroundsVisible: true,
	pointsOfInterest: []
});

export const mapStateReducer = (prevState: MapState, action: MapAction): MapState => {
	switch (action.type) {
		case MapActionType.SetBuoysWithoutHistory: {
			return {
				...prevState,
				buoysWithoutHistory: action.buoys,
			};
		}
		case MapActionType.SetBuoys: {
			const idBuoyDict = action.buoys
				// filter buoys without lat/lng
				.filter(({ latitude, longitude }) => latitude && Number.isFinite(latitude) && longitude && Number.isFinite(longitude))
				// (buoyId, buoy) dict
				.reduce((dict, buoy) => {
					dict[buoy.id] = buoy;
					return dict;
				}, {} as Record<string, Buoy>);

			return {
				...prevState,
				// buoys: action.buoys,
				buoys: idBuoyDict,
				selectedBuoyIds: []
			};
		}
		case MapActionType.SelectBuoys: {
			return {
				...prevState,
				selectedBuoyIds: action.buoyIds
			};
		}
		case MapActionType.SelectBuoy: {
			return {
				...prevState,
				selectedBuoyIds: [action.buoyId]
			};
		}
		case MapActionType.ToggleSelectBuoy: {
			let newSelectedBuoyIds = [];
			if (prevState.selectedBuoyIds?.includes(action.buoyId)) {
				newSelectedBuoyIds = prevState.selectedBuoyIds.filter(id => id !== action.buoyId);
			} else {
				newSelectedBuoyIds = [...prevState.selectedBuoyIds, action.buoyId];
			}
			return {
				...prevState,
				selectedBuoyIds: newSelectedBuoyIds,
			};
		}
		case MapActionType.ClearBuoySelection: {
			return {
				...prevState,
				selectedBuoyIds: []
			};
		}
		case MapActionType.SelectFishingZone: {
			return {
				...prevState,
				fishingZoneId: action.fishingZoneId,
				fishingZone: action.fishingZone
			};
		}
		case MapActionType.SelectBreedingGround: {
			return {
				...prevState,
				breedingGround: action.breedingGround
			};
		}
		case MapActionType.SetHistoryPeriodOption: {
			if (action.historyPeriodOption.type === 'TIME_RANGE') {
				const { from, to } = action.historyPeriodOption;
				return {
					...prevState,
					historyPeriodOption: { type: 'TIME_RANGE', from, to }
				};	
			};
			return {
				...prevState,
				historyPeriodOption: { type: 'LAST_N_HOURS', hours: action.historyPeriodOption.hours }
			};
		}
		case MapActionType.SetFishingZonesVisibility: {
			return {
				...prevState,
				fishingZonesVisible: action.visible,
			};
		}
		case MapActionType.SetBreedingGroundsVisibility: {
			return {
				...prevState,
				breedingGroundsVisible: action.visible,
			};
		}
		case MapActionType.SetPointsOfInterest: {
			return {
				...prevState,
				pointsOfInterest: action.pointsOfInterest,
			};
		}
		case MapActionType.SetCurrentMapTime: {
			return {
				...prevState,
				currentMapTime: action.currentMapTime,
			};
		}
	}
};
