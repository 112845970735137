
import React, { useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { buoyApi } from '../../../../api/buoy.api';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { BuoyPairAlarmFormModel, buoyPairAlarmValidationSchema, defaultBuoyPairAlarmForm } from './BuoyPairAlarm.type';
import { BuoyPairAlarmForm } from './BuoyPairAlarmForm';

interface Props {
	fstBuoyId: string;
	sndBuoyId: string;
	open: boolean;
	onClose?: () => void;
}

export const useStyles = makeStyles(_theme => {
	return {
		input: {
			width: '300px',
		},
	};
});

export const BuoyPairAlarmDialog = (props: Props) => {
	const { fstBuoyId, sndBuoyId, open, onClose } = props;
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [tab, setTab] = useState('0');

	const formik = useFormik<BuoyPairAlarmFormModel>({
		initialValues: defaultBuoyPairAlarmForm(),
		onSubmit: ({ distanceThreshold }: BuoyPairAlarmFormModel) => {
			buoyApi.pairBuoy({
				fstBuoyId,
				sndBuoyId,
				distanceThreshold,
			})
				.then(() => {
					enqueueSnackbar('Bove uspješno uparene', { variant: 'success' });
					onClose?.();
				})
				.catch(() => enqueueSnackbar('Greška prilikom uparivanja bova', { variant: 'error' }));
		},
		validationSchema: buoyPairAlarmValidationSchema,
	});

	return (
		<Dialog
			onClose={() => onClose?.()}
			open={open}
		>
			<div style={{
				padding: '0px 16px 0px 16px',
			}}>
				<DialogTitle style={{
					padding: '16px 16px 0px 0px',
				}}>
					<span style={{ fontWeight: 800 }}>Upari bovu</span>
				</DialogTitle>
				<div style={{ paddingTop: '30px' }}>
					<BuoyPairAlarmForm formik={formik} />
					<div style={{ marginTop: '30px'}}>
						<Button
							style={{ margin: '10px 0 16px 0' }}
							onClick={() => formik.handleSubmit() }
							variant='contained'
							color='primary'>
							Upari
						</Button>
						<Button
							style={{ margin: '10px 0 16px 10px' }}
							onClick={() => onClose ? onClose() : null}
							variant='contained'>
							Otkaži
						</Button>
					</div>
				</div>
			</div>
		</Dialog>
	);
};
