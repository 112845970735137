import { useRef, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, FormHelperText, MenuItem } from '@material-ui/core';
import _ from 'lodash';


interface Option {
	id: string | number | boolean;
	name: string;
}

export interface AsyncSelectProps {
	getOptions: () => Promise<Option[]>;
	onChange?: (value: string | null) => void;
	value?: string | number | null;
	name?: string;
	error?: boolean;
	helperText?: React.ReactNode;
	label?: string;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
	allowEmpty?: boolean;
}

export function AsyncSelect(props: AsyncSelectProps) {
	const { name, onChange, error, helperText, label, getOptions, disabled, style, className, allowEmpty } = props;
	const [options, setOptions] = useState<Option[]>([]);
	const [value, setValue] = useState<string | number>('');
	const [resolving, setResolving] = useState(false);
	const id = useRef(`async-select-${Math.round(Math.random() * 10000)}`);

	useEffect(() => {
		setValue(props.value ?? '');
	}, [props.value]);

	useEffect(() => {
		setResolving(true);
		getOptions()
			.then(newOptions => setOptions(newOptions))
			.finally(() => setResolving(false));
	}, [getOptions]);

	return <FormControl
		style={style}
		className={className}
		error={error}>
		<InputLabel htmlFor={id.current}>{label}</InputLabel>
		<Select
			label={label}
			value={value}
			onChange={e => {
				const newValue = e.target.value as string;
				setValue(newValue);
				if (onChange) {
					onChange(newValue != undefined ? newValue : null);
				}
			}}
			id={id.current}
			disabled={resolving || disabled}
		>
			{allowEmpty ? <MenuItem value={''}>/</MenuItem> : null}
			{
				options.map(option => (
					<MenuItem value={option.id as string} key={option.id.toString()}>
						{option.name}
					</MenuItem>
				))
			}
		</Select>
		<FormHelperText>{helperText}</FormHelperText>
	</FormControl>;
}

export default AsyncSelect;

