import React from 'react';
import { Topbar } from './layout/Topbar';
import { Storage, SupervisorAccount, Map, PinDropOutlined, Public, Room, ScatterPlot, NotificationsActive } from '@material-ui/icons';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { MapView } from './views/MapView';
import { authService } from '../api/auth.api';
import { UserRouter } from './views/users/UserRouter';
import { BuoyRouter } from './views/buoys/BuoyRouter';
import { RegionRouter } from './views/regions/RegionRouter';
import { PointOfInterestRouter } from './views/points-of-interest/PointOfInterestRouter';
import { AttributeDefinitionSetRouter } from './views/attribute-definition-sets/AttributeDefinitionSetRouter';
import { UserRole } from '../model/user';
import { FishingNetRouter, FishingNetsBaseRoute } from './views/fishing-nets/FishingNetRouter';
import { AlarmBaseRoute, AlarmRouter } from './views/alarms/AlarmRouter';

const MainRoutes = {
  Map: { label: 'Karta', route: '/map', icon: <Map style={{ verticalAlign: 'middle' }} fontSize={'small'} /> },
	Alarms: { label: 'Alarmi', route: '/alarms', icon: <NotificationsActive style={{ verticalAlign: 'middle' }} fontSize={'small'} /> },
};

export const App = () => {
  const history = useHistory();
  // if (!authService.isLoggedIn()) {
  //   history.push('/login');
  //   return <></>; // NOTE: if normal render path continues API error notifications will pop up
  // }
  const username = authService.getUserDetails()?.email;
  const onLogout = () => {
    authService.logout();
    history.push('/login');
  };

  return (
    <Topbar
      menuItems={[MainRoutes.Map, MainRoutes.Alarms]}
      username={username}
      onLogout={onLogout}>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/map' />
        </Route>
        <Route path='/map'>
          <MapView />
        </Route>
				<Route path={AlarmBaseRoute}>
          <AlarmRouter />
        </Route>
      </Switch>
    </Topbar>
  );
};
