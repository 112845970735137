import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { BuoyGpsSettingsFormModel, GpsNavigationModeOpts, OperatingModeOpts } from './BuoyGpsSettings.type';
import { AsyncSelect } from '@telemetris/ui-components';
import { getFieldProps } from '../../../../utility/formik-helpers';

const useStyles = makeStyles({
	input: {
		width: '300px',
	},
});

const FormikTypeHack = () => {
	return useFormik<BuoyGpsSettingsFormModel>({} as any);
};

interface Props {
	formik: ReturnType<typeof FormikTypeHack>;
}

export const BuoyGpsSettingsForm = (props: Props) => {
	const { formik } = props;
	const classes = useStyles();

	return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
		<AsyncSelect
			className={classes.input}
			label='Način rada GPS-a'
			allowEmpty
			getOptions={() => Promise.resolve(Object.entries(GpsNavigationModeOpts).map(([key, value]) => ({ id: value, name: key })))}
			{...getFieldProps(formik, 'gpsNavigationMode')}
		/>
		<TextField
			className={classes.input}
			type='number'
			label='Max trajanje geolociranja'
			{...getFieldProps(formik, 'lockTimeout')}
		/>
		<AsyncSelect
			className={classes.input}
			label='Algoritam rada GPS-a'
			allowEmpty
			getOptions={() => Promise.resolve(Object.entries(OperatingModeOpts).map(([key, value]) => ({ id: value, name: key })))}
			{...getFieldProps(formik, 'operatingMode')}
		/>
		{
			formik.values.operatingMode === 'max_hdop' ? <>
				<TextField
					className={classes.input}
					type='number'
					label='Ciljani HDOP'
					{...getFieldProps(formik, 'targetHDOP')}
				/>
				<TextField
					className={classes.input}
					type='number'
					label='Ekstra vrijeme'
					{...getFieldProps(formik, 'extraTime')}
				/>
			</> : null
		}
		{
			formik.values.operatingMode === 'max_hepe' ? <TextField
					className={classes.input}
					type='number'
					label='Ciljani HEPE'
					{...getFieldProps(formik, 'targetHEPE')}
				/> : null
		}
		{
			formik.values.operatingMode === 'no_movement' ? <>
				<TextField
					className={classes.input}
					type='number'
					label='Maksimalna udaljenost'
					{...getFieldProps(formik, 'maxDistance')}
				/>
				<TextField
					className={classes.input}
					type='number'
					label='Broj ponavljanja'
					{...getFieldProps(formik, 'distanceRepetitions')}
				/>
			</> : null
		}
	</div>;
};
