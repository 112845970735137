import React from 'react';
// TODO: see chartjs-date-adapter-hr // import 'chartjs-adapter-date-fns';
import './chartjs-date-adapter-hr';
import Chart, { ChartPoint, InteractionMode } from 'chart.js';

export interface TimeSeriesPoint {
	y: number | string;
	t: Date | string | number;
}

interface AxesSettings {
	min: number,
	max: number
}

interface TimeSeriesChartProps {
	legendLabel: string;
	timeseries: TimeSeriesPoint[];
	// axes
	yAxesSettings?: AxesSettings,
	// colors
	lineColor: string;
	textColor: string;
	gridLinesColor: string;
}

const convertHexToRGBA = (hex: string, opacity: number) => {
	const tempHex = hex.replace('#', '');
	const r = parseInt(tempHex.substring(0, 2), 16);
	const g = parseInt(tempHex.substring(2, 4), 16);
	const b = parseInt(tempHex.substring(4, 6), 16);

	return `rgba(${r},${g},${b},${opacity})`;
};

export const TimeSeriesChart = (props: TimeSeriesChartProps) => {
	const canvasRef = React.useRef<HTMLCanvasElement>(null);
	const chartRef = React.useRef<Chart>();

	React.useEffect(() => {
		const canvasEl = canvasRef.current;
		if (!canvasEl) return;
		const ctx = canvasEl.getContext('2d');
		if (!ctx) return;
		// TODO: on props change update only relevant parts
		if (chartRef.current) chartRef.current.destroy();

		const gradientBckgColor = ctx.createLinearGradient(0, 0, 0, 400);
		gradientBckgColor.addColorStop(0, convertHexToRGBA(props.lineColor, 0.16));
		gradientBckgColor.addColorStop(1, convertHexToRGBA(props.lineColor, 0.16));

		const chart = new Chart(ctx, getLineChartSettings(
			props.legendLabel,
			props.lineColor,
			props.textColor,
			props.gridLinesColor,
			gradientBckgColor,
			props.timeseries,
			props.yAxesSettings
		));

		chartRef.current = chart;

		return () => {
 chartRef.current?.destroy(); 
};
		// TODO: on props change update only relevant parts
	}, [props.legendLabel, props.lineColor, props.textColor, props.gridLinesColor, props.timeseries, props.yAxesSettings]);

	return (
		<canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
	);
};

const getLineChartSettings = (
	legendLabel: string,
	lineColor: string,
	textColor: string,
	gridLinesColor: string,
	gradientBckgColor: CanvasGradient,
	timeseries: ChartPoint[],
	yAxesSettings?: AxesSettings
): Chart.ChartConfiguration => ({
	type: 'line',
	data: {
		datasets: [
			{
				backgroundColor: gradientBckgColor,
				data: timeseries,
				borderColor: lineColor,
				label: legendLabel,
				cubicInterpolationMode: 'monotone'
			}
		]
	},
	options: {
		elements: {
			point: {
				radius: 0
			}
		},
		legend: {
			display: true,
			labels: {
				fontColor: textColor
			}
		},
		responsive: true,
		animation: undefined,
		maintainAspectRatio: false,
		aspectRatio: 1,
		scales: {
			xAxes: [
				{
					type: 'time',
					distribution: 'linear',
					time: {
						unit: 'day',
						tooltipFormat: 'Pp',
						// TODO: see chartjs-date-adapter-hr
						// adapters: {
						// 	date: {
						// 		locale: hrLocale,
						// 	},
						// },
					},
					ticks: {
						fontColor: textColor,
						padding: 5
					},
					gridLines: {
						tickMarkLength: 0,
						display: true,
						color: gridLinesColor
					},
				}
			],
			yAxes: [
				{
					scaleLabel: {
						display: false,
					},
					ticks: {
						fontColor: textColor,
						padding: 5,
						max: yAxesSettings ? yAxesSettings.max : undefined,
						min: yAxesSettings ? yAxesSettings.min : undefined,
					},
					gridLines: {
						tickMarkLength: 0,
						display: true,
						color: gridLinesColor
					},
				}
			]
		},
		layout: {
			padding: {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			}
		},
		tooltips: {
			intersect: false,
			mode: 'index' as InteractionMode,
		}
	}
});