import React from 'react';
import { TmModal } from '../../components/reusable/TmModal';

interface ConfirmModalLabels {
	message: string;
	yes: string;
	no: string;
}

const initialContextState = {
	setLabels: (_labels: ConfirmModalLabels) => { /* do nothing */ },
	setOpen: (_open: boolean) => { /* do nothing */ },
};

const ConfirmModalContext = React.createContext(initialContextState);

// NOTE: we need to resolve Promise later at some point in time
let resolve: (any: boolean) => void;
let confirmPromise: Promise<boolean>;
const resetConfirmPromise = () => {
	// TODO: resolve old promise just in case
	if (resolve) resolve(false);
	confirmPromise = new Promise((res) => {
		resolve = res;
	});
};
resetConfirmPromise();

export const useConfirmModal: () => (labels?: ConfirmModalLabels) => Promise<boolean> = () => {
	const { setOpen, setLabels } = React.useContext(ConfirmModalContext);
	return (labels?: ConfirmModalLabels) => {
		resetConfirmPromise();
		if (labels) setLabels(labels);
		setOpen(true);
		return confirmPromise;
	};
};

export const ConfirmModalProvider = (props: React.PropsWithChildren<any>) => {
	const [open, setOpen] = React.useState(false);
  const [labels, setLabels] = React.useState<ConfirmModalLabels>({
		message: 'Jeste li sigurni?',
		yes: 'Da',
		no: 'Ne'
	});

  return (
    <ConfirmModalContext.Provider value={{ setLabels, setOpen }}>
			<TmModal message={labels.message} yesLabel={labels.yes} noLabel={labels.no} open={open}
				onClose={(confirmed: boolean) => {
					setOpen(false);
					if (resolve) resolve(confirmed);
				}}
			/>
      {props.children}
    </ConfirmModalContext.Provider>
  );
};
