import React from 'react';
import { Card, makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { MapDrawer } from '../MapDrawer';
import { BuoyTrackerMap } from './buoy-tracker-map/BuoyTrackerMap';
import { mapStateReducer, MapActionType, getInitialState } from './buoy-tracker-map/map-state';
import { useSnackbar } from 'notistack';
import { GenericRequestErrorMsg } from '../utility/general-messages';
import { AsyncSearchBuoy } from './buoys/AsyncSearchBuoy';
import { buoyApi, Buoy } from '../../api/buoy.api';
import { pointOfInterestApi } from '../../api/point-of-interest.api';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { endOfDay, isToday } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import hrLocale from 'date-fns/locale/hr';
import * as _ from 'lodash';
import { useIsPortrait } from '../utility/responsive';
import { useUserRole } from '../utility/useUserRole';

const useMapViewStyles = makeStyles({
  mapOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
  },
  bottomLeft: {
    bottom: 15,
    left: 15,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& > *': {
      marginRight: 10,
      pointerEvents: 'all'
    }
  },
  topLeft: {
    top: 12,
    left: 60,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& > *': {
      marginRight: 10,
      pointerEvents: 'all'
    }
  },
  card: {
    padding: 10
  },
  cardLabel: {
    fontWeight: 600,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
});

export const MapView = () => {
  const classes = useMapViewStyles();
  const [drawerDomEl, setDrawerDomEl] = React.useState<HTMLDivElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const isPortrait = useIsPortrait();
  const [mapState, mapDispatch] = React.useReducer(mapStateReducer, getInitialState());

  return (
    <div
      ref={el => setDrawerDomEl(el)}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <MapDrawer
        ids={mapState.selectedBuoyIds}
        container={drawerDomEl}
        width='35%'
        onClose={() => mapDispatch({ type: MapActionType.ClearBuoySelection })}
        mapState={mapState} mapDispatch={mapDispatch}
      />
      <div key='buoy-tracker-map-container' style={{
        height: '100%',
        width: '100%',
      }}>
        <BuoyTrackerMap mapState={mapState} mapDispatch={mapDispatch} />
      </div>
      <div key='overlay-controls-container-bottom-left' className={`${classes.mapOverlay} ${classes.bottomLeft}`}>
        {
          mapState.fishingZonesVisible && <Card className={classes.card}>
            <div><span className={classes.cardLabel}>Ribolovna zona:</span> {mapState.fishingZoneId || '/'}</div>
            <div><span className={classes.cardLabel}>Površina:</span> {mapState.fishingZone?.povrs || '/'}</div>
          </Card>
        }
      </div>
    </div>
  );
};
