import Axios from 'axios';
import jwtDecode from 'jwt-decode';

interface Options {
	localStorageKey?: string;
}

export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'ACCESS_TOKEN';

export interface MandatoryTokenDetails {
	id: string;
	iat: number;
	exp: number;
}

export class GenericAuthService<T extends MandatoryTokenDetails> {

	private localStorageKey: string;
	private accessToken: string | null = null;
	private userDetails: T | null = null;

	constructor(protected readonly loginRoute: string, options?: Options) {
		this.localStorageKey = options?.localStorageKey || ACCESS_TOKEN_LOCAL_STORAGE_KEY;
		this.validateAndSetAccessToken(localStorage.getItem(this.localStorageKey));
	}

	login(username: string, password: string): Promise<void> {
		return Axios.post(
			this.loginRoute,
			{
				username,
				password,
			},
		)
			.then(res => this.validateAndSetAccessToken(res?.data?.access_token))
			.catch(() => {
				throw new Error('Login failed');
			});
	}

	getAccessToken(): string | null {
		return this.accessToken;
	}

	getUserDetails(): T | null {
		return this.userDetails;
	}

	isLoggedIn(): boolean {
		return !!this.accessToken;
	}

	isUserLoggedIn(id: string): boolean {
		return this.userDetails?.id === id;
	}

	logout() {
		localStorage.removeItem(this.localStorageKey);
		this.accessToken = null;
	}

	private validateAndSetAccessToken(accessToken: string | null) {
		if (!accessToken) {
			this.userDetails = null;
			this.accessToken = null;
			return;
		}

		const tokenDetails = jwtDecode(accessToken) as MandatoryTokenDetails;
		if (tokenDetails.exp * 1000 <= Date.now()) {
			this.userDetails = null;
			this.accessToken = null;
			return;
		}

		this.accessToken = accessToken;
		localStorage.setItem(this.localStorageKey, accessToken);
		this.userDetails = tokenDetails as T;
	}

}
