
import React, { useState } from 'react';
import { Dialog, DialogTitle, Button, makeStyles, DialogActions, DialogContent } from '@material-ui/core';

const useStyles = makeStyles(() => {
	return {

	};
});

interface Props {
	title?: string;
    message?: string;
    yesLabel: string;
    noLabel: string;
	open: boolean;
	onClose?: (yesClicked: boolean) => void;
}

export const TmModal = (props: Props) => {
	const { title, message, yesLabel, noLabel, open, onClose } = props;
	const classes = useStyles();

	return (
        <Dialog
            open={open}
            onClose={() => onClose && onClose(false)}
        >
			<div>
				{
					title && <DialogTitle>
						<span style={{ fontWeight: 800 }}>{title}</span>
					</DialogTitle>
				}

				{
					message && <DialogContent>
						{message}
					</DialogContent>
				}
				
				<DialogActions>
					<Button
						onClick={() => onClose && onClose(false)}
						variant='contained'>
						{noLabel}
					</Button>
					<Button
						onClick={() => onClose && onClose(true)}
						variant='contained'
						color='primary'>
						{yesLabel}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};
