import { ThemeOptions as MuiThemeOptions, Theme as MuiTheme, createMuiTheme } from '@material-ui/core';
// import { Theme } from 'src/contexts/theme/ThemeContext';
import { Theme, ThemeType } from './ThemeContext';

export const themeToMuiTheme = (theme: Theme): MuiTheme => {
	return createMuiTheme({
		...muiThemeOverrides,
		palette: {
			...muiThemeOverrides.palette,
			type: theme.type === ThemeType.Light ? 'light' : 'dark',
			primary: {
				main: theme.type === ThemeType.Light ? theme.light.main : theme.dark.main
			},
			secondary: {
				main: theme.type === ThemeType.Light ? theme.light.main : theme.dark.main
			}
		}
	});
};

const muiThemeOverrides: MuiThemeOptions = {
	palette: {
		// type: 'dark',
		// type: 'light',
		// background: {
		// 	default: 'rgb(20, 20, 20)'
		// },
		// primary: {
		// main: '',
		// main: '#00897b'
		// contrastText: '#00878c'
		// },
		// secondary: {
		// main: '#4492eb',
		// main: '#00897b'
		// }
		// secondary: {
		// 	main: '#4492eb',
		// 	light: '#eb44a8',
		// 	dark: '#eb44a8',
		// 	// contrastText: '#00878c'
		// }
	},
	props: {
		MuiButton: {
			size: 'small',
			variant: 'contained',
			disableElevation: true
		},
		MuiFilledInput: {
			margin: 'dense',
		},
		MuiFormControl: {
			margin: 'dense',
			variant: 'outlined',
		},
		MuiFormHelperText: {
			margin: 'dense',
		},
		MuiIconButton: {
			size: 'small',
		},
		MuiInputBase: {
			margin: 'dense',
		},
		MuiInputLabel: {
			margin: 'dense',
		},
		MuiListItem: {
			dense: true,
		},
		MuiOutlinedInput: {
			margin: 'dense',
		},
		MuiFab: {
			size: 'small',
		},
		MuiTable: {
			size: 'small',
		},
		MuiTextField: {
			margin: 'dense',
			variant: 'outlined'
		},
		MuiToolbar: {
			variant: 'dense',
		},
		MuiSelect: {
			variant: 'outlined',
			margin: 'dense'
		},
		// MuiTableRow: {
		// 	classes: {

		// 	}
		// }
	},
};