import { Dialog, DialogTitle, Button, makeStyles, DialogActions, DialogContent } from '@material-ui/core';


export interface ConfirmModalProps {
  title?: string;
  message?: string;
  yesLabel: string;
  noLabel: string;
  open: boolean;
  onClose?: (yesClicked: boolean) => void;
}

const useStyles = makeStyles(() => {
	return {
	};
});

export function ConfirmModal(props: ConfirmModalProps) {
  const { title, message, yesLabel, noLabel, open, onClose } = props;
	const classes = useStyles();

	return (
        <Dialog
            open={open}
            onClose={() => onClose && onClose(false)}
        >
			<div>
				{
					title && <DialogTitle>
						<span style={{ fontWeight: 500 }}>{title}</span>
					</DialogTitle>
				}

				{
					message && <DialogContent>
						{message}
					</DialogContent>
				}
				
				<DialogActions>
					<Button
						onClick={() => onClose && onClose(false)}
						variant='contained'>
						{noLabel}
					</Button>
					<Button
						onClick={() => onClose && onClose(true)}
						variant='contained'
						color='primary'>
						{yesLabel}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
}

export default ConfirmModal;
