import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { buoyApi, BuoyFilter, BuoySearchResult } from '../../../api/buoy.api';

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	textField: {
		margin: '0'
	},
	searchResultEntry: {
		display: 'flex',
		flexDirection: 'column'
	}
}));

export const debounce = (fn: (...args: any[]) => void, timeoutMs: number) => {
	let timeoutHandle: any;
	return (...args: any[]) => {
		clearTimeout(timeoutHandle);
		timeoutHandle = setTimeout(() => {
			timeoutHandle = undefined;
			fn(...args);
		}, timeoutMs);
	};
};

export const useDebounce = (value: any, timeoutMs: number) => {
	const [debouncedValue, setDebouncedValue] = React.useState(value);

	const debouncedSetValue = React.useCallback(
		debounce((value: any) => {
			setDebouncedValue(value);
		}, timeoutMs),
		[timeoutMs]
	);

	React.useEffect(() => {
		debouncedSetValue(value);
	}, [value]);

	return debouncedValue;
};

interface Props {
	onChange?: (buoyId: string | null) => void
	filter?: BuoyFilter;
	disabled?: boolean;
	placeholder?: string;
	style?: React.CSSProperties,
}

export const AsyncSearchBuoy = (props: Props) => {
	const disabled = props.disabled === true ? true : false;
	const classes = useStyles();
	const [value, setValue] = React.useState<BuoySearchResult | null>(null);
	const [searchQuery, setSearchQuery] = React.useState('');
	const debouncedSearchQuery = useDebounce(searchQuery, 200);
	const [options, setOptions] = React.useState<BuoySearchResult[]>([]);
	const loaded = React.useRef(false);

	React.useEffect(() => {
		let active = true;
		if (!debouncedSearchQuery) {
			setOptions([]);
			return;
		};

		buoyApi.search(debouncedSearchQuery, props.filter)
			.then(res => setOptions(res));

		return () => {
			active = false;
		};
	}, [value, debouncedSearchQuery]);

	return (
		<Autocomplete
			disabled={disabled}
			style={props.style || { width: 300 }}
			getOptionLabel={() => searchQuery}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(_event: any, newValue: BuoySearchResult | null) => {
				if (props.onChange) props.onChange(newValue ? newValue.buoyId : null);
				// setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(_, newInputValue) => {
				setSearchQuery(newInputValue);
			}}
			renderInput={(params) => (
				<TextField {...params} label={props.placeholder || 'Pretražite bove...'} fullWidth className={classes.textField} />
			)}
			renderOption={(option) => {
				const startIdx = option.matchValue.toLowerCase().indexOf(searchQuery.toLowerCase());
				const endIdx = startIdx + searchQuery.length;
				const parts = [
					{ text: option.matchValue.slice(0, startIdx), highlight: false },
					{ text: option.matchValue.slice(startIdx, endIdx), highlight: true },
					{ text: option.matchValue.slice(endIdx), highlight: false }
				];
				return (
					<div className={classes.searchResultEntry}>
						<div>
							<span style={{ whiteSpace: 'pre-wrap' }}>{`${option.matchProperty}: `}</span>
							{parts.map((part, index) => (
								<span key={index} style={{ whiteSpace: 'pre-wrap', fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
						</div>
						<div>
							<Typography variant="body2" color="textSecondary">
								{option.buoyName}
							</Typography>
						</div>
					</div>
				);
			}}
		/>
	);
};
