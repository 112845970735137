import React from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { useFormik } from 'formik';
import { BuoyPairAlarmFormModel } from './BuoyPairAlarm.type';
import { getFieldProps } from '../../../utility/formik-helpers';
import { firefoxInputIntegerKeypressHack } from '../../../utility/workarounds';

const useStyles = makeStyles({
	input: {
		width: '300px',
	},
});

const FormikTypeHack = () => {
	return useFormik<BuoyPairAlarmFormModel>({} as any);
};

interface Props {
	formik: ReturnType<typeof FormikTypeHack>;
}

export const BuoyPairAlarmForm = (props: Props) => {
	const { formik } = props;
	const classes = useStyles();

	return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
		<TextField
			className={classes.input}
			label='Maksimalna udaljenost'
			type='number'
			InputProps={{
				endAdornment: <InputAdornment position='end'>m</InputAdornment>
			}}
			onKeyPress={firefoxInputIntegerKeypressHack}
			onFocus={e => e.target.select()}
			{...getFieldProps(formik, 'distanceThreshold')}
		/>
	</div>;
};
