import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { BuoyChangePasswordFormModel, buoyChangePasswordValidationSchema, defaultBuoyChangePasswordFormModel } from './BuoyChangePassword.type';
import { BuoyChangePasswordForm } from './BuoyChangePasswordForm';

interface Props {
	onSend: (password: string) => void;
	onCancel?: () => void;
}

const useStyles = makeStyles({
	input: {
		width: '300px',
	},
});

export const BuoyChangePassword = (props: Props) => {
	const { onSend, onCancel } = props;
	const classes = useStyles();

	const formik = useFormik<BuoyChangePasswordFormModel>({
		initialValues: defaultBuoyChangePasswordFormModel(),
		onSubmit: ({ password }: BuoyChangePasswordFormModel) => {
			onSend(password);
		},
		validationSchema: buoyChangePasswordValidationSchema,
	});
	
	return (
		<div>
			<BuoyChangePasswordForm formik={formik} />
			<div style={{ marginTop: '30px'}}>
				<Button
					style={{ margin: '10px 0 16px 0' }}
					onClick={() => formik.handleSubmit() }
					variant='contained'
					color='primary'>
					Promijeni
				</Button>
				<Button
					style={{ margin: '10px 0 16px 10px' }}
					onClick={() => onCancel ? onCancel() : null}
					variant='contained'>
					Otkaži
				</Button>
			</div>
		</div>
	);
};
