import React, { useState } from 'react';
import { Card, Button, makeStyles, CircularProgress, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Save } from '@material-ui/icons';
import { getBuoyListRoute } from './BuoyRouter';
import { LabeledText } from '../../reusable/LabeledText';
import { useSnackbar } from 'notistack';
import { GenericRequestErrorMsg } from '../../utility/general-messages';
import { TimeSeriesChart } from '../../reusable/charts/TimeSeriesChart';
import { BuoyConfigureDialog } from '../../views/buoys/buoy-device-message/BuoyConfigureDialog';
import { ExportBuoyButton } from '../../reusable/ExportBuoyButton';
import { useUserRole } from '../../utility/useUserRole';
import { BuoyDownlinkButtons } from './BuoyDownlinkButtons';
import { BuoyGeolocationHistoryTable } from './BuoyGeolocationHistoryTable';
import { Autocomplete } from '@material-ui/lab';
import { subMonths } from 'date-fns';
import { BuoyDownlinkAuditLogTable } from './buoy-downlink-audit-log/BuoyDownlinkAuditLogTable';
import { ThemeType, useCurrentTheme } from '../../../contexts/theme/ThemeContext';
import { ApiBuoyUpdate, Buoy, buoyApi } from '../../../api/buoy.api';
import { AttrDef, AttrDefSet, attrDefSetApi, AttrUpdateForBuoy } from '../../../api/attr-def-set.api';
import { BuoyPairAlarmTable } from './buoy-pair-alarm/BuoyPairAlarmTable';

export const useBuoyShowStyles = makeStyles((theme) => {
	return {
		container: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
		},
		card: {
			margin: '20px 0',
			width: '100%',
			maxWidth: '1200px',

			[theme.breakpoints.down('md')]: {
				margin: 0,
			},
		},
		title: {
			fontSize: theme.typography.h5.fontSize,
			fontWeight: 500,
			paddingBottom: '12px',
		},
		configButtons: {
			float: 'right',
		},
		buttonContainer: {
			display: 'flex',
			width: '100%',
			padding: '10px 10px',
			backgroundColor: theme.palette.divider,
		},
		saveButton: {
			marginRight: '10px',
		},
		exportButton: {
			marginLeft: 'auto',
		},
		loading: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
		},
		info: {
			display: 'flex',
			flexWrap: 'wrap',
			'& > *': {
				marginRight: 40,
			},
		},
		chartContainer: {
			height: '400px',
			marginTop: '50px',
		},
	};
});

export const BuoyShow = () => {
	const { type, colors } = useCurrentTheme();
	const classes = useBuoyShowStyles();
	const { id } = useParams() as { id: string };
	const [dirty, setDirty] = useState(false);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { isViewOnlyUser } = useUserRole();

	const [buoy, setBuoy] = React.useState<Buoy | undefined>(undefined);
	const [attrDefSetId, setAttrDefSetId] = React.useState<string | undefined>(undefined);
	const [batteryHist, setBatteryHist] = React.useState<{ t: string; y: string }[]>();
	const [batteryVoltageHist, setBatteryVoltageHist] = React.useState<{ t: string; y: string }[]>();
	const [temperatureHist, setTemperatureHist] = React.useState<{ t: string; y: string }[]>();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [attrDefSets, setAttrDefSets] = React.useState<AttrDefSet[]>([]);
	const [attrDefs, setAttrDefs] = React.useState<AttrDef[]>([]);
	const [attrs, setAttrs] = React.useState<Record<string, AttrUpdateForBuoy>>({});

	const saveBuoyChanges = React.useCallback(async () => {
		setDirty(true);
		if (!buoy) return;
		try {
			const apiBuoyUpdate: ApiBuoyUpdate = {
				id: buoy.id,
				attributeDefinitionSetId: attrDefSetId,
				// TODO: since we have some stale/hanging attributes this way we filter them out
				attributes: Object.values(attrs).filter((attr) => attrDefs.some((def) => def.id === attr.definitionId)),
				geoFenceAlarmEnabled: buoy.geoFenceAlarmEnabled,
				geoFenceRadiusInMeters: buoy.geoFenceRadiusInMeters,
			};
			const updatedBuoy = await buoyApi.update(apiBuoyUpdate);
			enqueueSnackbar(`Bova '${updatedBuoy.name}' uspješno izmijenjena`, { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Greška prilikom spremanja bove...', { variant: 'error' });
		}
		setDirty(false);
	}, [buoy, attrs]);

	const onBuoyId = React.useCallback(async () => {
		if (!id) return;
		setBuoy(undefined);
		const buoy = await buoyApi.get(id);
		console.log('buoy', buoy);
		setBuoy(buoy);
		setAttrDefSetId(buoy.attributeDefinitionSetId);
		const attributes = await buoyApi.getAttributes(id);
		// create dict of (id, attributes) for O(1) access
		setAttrs(
			attributes.reduce((acc, cur) => {
				acc[cur.definitionId] = cur;
				return acc;
			}, {} as Record<string, AttrUpdateForBuoy>)
		);
	}, [id]);
	React.useEffect(() => {
		onBuoyId();
	}, [onBuoyId]);

	const onAttrDefSetIdChange = React.useCallback(async () => {
		if (!attrDefSetId) return;
		const attrDefs = await attrDefSetApi.getDefinitions(attrDefSetId);
		setAttrDefs(attrDefs);
	}, [attrDefSetId]);
	React.useEffect(() => {
		onAttrDefSetIdChange();
	}, [onAttrDefSetIdChange]);

	React.useEffect(() => {
		attrDefSetApi.find(undefined, { page: 0, size: 1000000 }).then((res) => {
			setAttrDefSets(res.data);
		});
	}, []);

	const fetchTelemetry = React.useCallback(async (id: string) => {
		try {
			const now = new Date();
			const timeRange = [subMonths(now, 1), now];
			const [batteryHist, batteryVoltageHist, temperatureHist] = await Promise.all([
				buoyApi.getBuoyTelemetry(id, 'battery', ...timeRange),
				buoyApi.getBuoyTelemetry(id, 'battery_voltage', ...timeRange),
				buoyApi.getBuoyTelemetry(id, 'temperature', ...timeRange),
			]);
			setBatteryHist(batteryHist);
			setBatteryVoltageHist(batteryVoltageHist);
			setTemperatureHist(temperatureHist);
		} catch (error) {
			enqueueSnackbar(GenericRequestErrorMsg('telemetrije'), { variant: 'error' });
		}
	}, []);
	React.useEffect(() => {
		if (!id) return;
		fetchTelemetry(id);
	}, [id]);

	return (
		<div className={classes.container}>
			<Card className={classes.card}>
				{!buoy ? (
					<div className={classes.loading}>
						<CircularProgress size={80} thickness={2} />
					</div>
				) : (
					<div
						style={{
							padding: '20px',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<div className={classes.title}>{buoy.name}</div>
							{buoy && (
								<div className={classes.configButtons}>
									<BuoyDownlinkButtons buoys={[buoy]} />
								</div>
							)}
							<BuoyConfigureDialog buoys={[buoy]} open={dialogOpen} onClose={() => setDialogOpen(false)} />
						</div>
						<div
							style={
								{
									// padding: '20px'
								}
							}
							className={classes.info}
						>
							<LabeledText label="Šifra uređaja" value={buoy.deviceKey} />
							<LabeledText label="Vrijeme zadnje poruke" value={buoy?.lastMessageAt?.toLocaleString('hr') || '/'} />
							<LabeledText label='Vrijeme zadnje geolokacije' value={buoy?.lastGeoLocAt?.toLocaleString('hr') || '/'} />
							<LabeledText label="Baterija" value={buoy.batteryPercentage ? `${buoy?.batteryPercentage}%` : '/'} />
							<LabeledText label="Temperatura" value={buoy.temperature ? `${buoy?.temperature}°C` : '/'} />
							<LabeledText label="Status" value={buoy.status || '/'} />
						</div>
						{/* TODO: currently there isn't large number of buoy attribute sets but in future it could be -> we should do it async and not load everything at once */}
						<div>
							<Autocomplete
								disabled={isViewOnlyUser}
								freeSolo
								options={attrDefSets}
								// TODO: optimize, this should be O(1) if there is a lot of attr def sets
								value={attrDefSets.find(({ id }) => attrDefSetId === id) || ('' as any)}
								getOptionLabel={(option: AttrDefSet) => option.name || ''}
								getOptionSelected={(option: AttrDefSet, value?: AttrDefSet) => option.id === ((value as any) as string)}
								style={{ width: 300, marginTop: '12px' }}
								renderInput={(params) => <TextField {...params} label="Set atributa" />}
								onChange={(_: any, val: AttrDefSet | string | null) => setAttrDefSetId((val as any)?.id || undefined) as any}
							/>
						</div>
						{/* attributes */}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								// flexWrap: 'wrap',
							}}
						>
							{attrDefs.map((def) => {
								return (
									<div
										key={`attribute-${def.name}`}
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											// justifyContent: 'space-between'
										}}
									>
										<TextField
											disabled={isViewOnlyUser}
											label={def.name}
											value={(attrs[def.id] && attrs[def.id].value) || ''}
											onChange={(e: any) => {
												const value = e.target.value;
												if (!attrs[def.id]) {
													setAttrs({
														...attrs,
														[def.id]: {
															definitionId: def.id,
															value,
														},
													});
													return;
												}
												setAttrs({
													...attrs,
													[def.id]: {
														...attrs[def.id],
														value,
													},
												});
											}}
											style={{
												marginRight: '20px',
											}}
										/>
									</div>
								);
							})}
						</div>
						<div style={{
							marginTop: '25px',
							display: 'flex',
							alignItems: 'center'
						}}>
							<FormControlLabel
								label='GeoFencing alarm omogućen'
								control={<Switch
									checked={buoy.geoFenceAlarmEnabled}
									onChange={({ target: { checked } }) => setBuoy({ ...buoy, geoFenceAlarmEnabled: checked })}
								/>}
							/>
							<TextField
								disabled={!buoy.geoFenceAlarmEnabled}
								type='number'
								value={buoy.geoFenceRadiusInMeters}
								onChange={e => setBuoy({ ...buoy, geoFenceRadiusInMeters: e.target.value ? parseFloat(e.target.value) : buoy.geoFenceRadiusInMeters })}
							/>
						</div>
					</div>
				)}

				<div
					className={classes.chartContainer}
					style={
						{
							// margin: isPortrait ? undefined : '15px'
							// margin: '15px'
						}
					}
				>
					{!temperatureHist ? (
						<div className={classes.loading}>
							<CircularProgress size={80} thickness={2} />
						</div>
					) : (
						<TimeSeriesChart
							legendLabel="Temperatura °C"
							timeseries={temperatureHist}
							lineColor={colors.main}
							textColor={type === ThemeType.Dark ? 'white' : 'black'}
							gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
							yAxesSettings={{ min: 0, max: 60 }}
						/>
					)}
				</div>
				<div
					className={classes.chartContainer}
					style={
						{
							// margin: isPortrait ? undefined : '15px'
							// margin: '15px'
						}
					}
				>
					{!batteryHist ? (
						<div className={classes.loading}>
							<CircularProgress size={80} thickness={2} />
						</div>
					) : (
						<TimeSeriesChart
							legendLabel="Baterija %"
							timeseries={batteryHist}
							lineColor={colors.main}
							textColor={type === ThemeType.Dark ? 'white' : 'black'}
							gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
							yAxesSettings={{ min: 0, max: 100 }}
						/>
					)}
				</div>
				<div
					className={classes.chartContainer}
					style={
						{
							// margin: isPortrait ? undefined : '15px'
							// margin: '15px'
						}
					}
				>
					{!batteryVoltageHist ? (
						<div className={classes.loading}>
							<CircularProgress size={80} thickness={2} />
						</div>
					) : (
						<TimeSeriesChart
							legendLabel="Napon baterije (V)"
							timeseries={batteryVoltageHist}
							lineColor={colors.main}
							textColor={type === ThemeType.Dark ? 'white' : 'black'}
							gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
							yAxesSettings={{ min: 0, max: 5 }}
						/>
					)}
				</div>
				<div>
					<BuoyGeolocationHistoryTable buoyId={id} />
				</div>
				<div>
					<BuoyDownlinkAuditLogTable buoyId={id} />
				</div>
				<div>
					<BuoyPairAlarmTable buoyId={id} />
				</div>

				<div className={classes.buttonContainer}>
					{!isViewOnlyUser && (
						<Button
							disabled={dirty}
							onClick={() => saveBuoyChanges()}
							startIcon={<Save />}
							className={classes.saveButton}
							variant="contained"
							color="primary"
						>
							Spremi izmjene
						</Button>
					)}
					<Button onClick={() => history.push(getBuoyListRoute())}>Odustani</Button>
					<ExportBuoyButton className={classes.exportButton} id={id} />
				</div>
			</Card>
		</div>
	);
};
