import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import hrLocale from 'date-fns/locale/hr';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import { Root } from './app/Root';

ReactDOM.render(
  <StrictMode>
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
    	<Root />
		</MuiPickersUtilsProvider>
  </StrictMode>,
  document.getElementById('root')
);
