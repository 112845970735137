import { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { firefoxInputRealKeypressHack } from '@telemetris/utils/frontend';


export interface DegreeInputProps {
	decimalMode?: boolean;
	value?: number;
	onChange?: (value: number) => void;
	name?: string;
	error?: boolean;
	helperText?: React.ReactNode;
	label?: string;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export function DegreeInput(props: DegreeInputProps) {
	const { decimalMode, name, onChange, error, helperText, label, disabled, style, className } = props;
	const [degreeValue, setDegreeValue] = useState<string>('');
	const [decimalValue, setDecimalValue] = useState<string>('');

	useEffect(() => {
		const degree: string = decimalToDegree(props.value);
		if (props.value !== degreeToDecimal(degreeValue) && degree) {
			setDegreeValue(degree);
		}
	}, [props.value]);

	useEffect(() => {
		if (props.value == null || Number.isNaN(props.value)) return;
		setDecimalValue(props.value.toString());
	}, [props.value]);

	return decimalMode ?
		<TextField
			type='number'
			onKeyPress={firefoxInputRealKeypressHack}
			style={style}
			className={className}
			name={name}
			label={label}
			error={error}
			helperText={helperText}
			disabled={disabled}
			value={decimalValue}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				setDecimalValue(e.target.value);
				onChange && onChange(Number.parseFloat(e.target.value));
			}}
		/>
		: <TextField
			style={style}
			className={className}
			name={name}
			label={label}
			error={error}
			helperText={helperText}
			disabled={disabled}
			value={degreeValue}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const newValue: string = e.target.value;
				setDegreeValue(newValue);
				onChange && onChange(degreeToDecimal(newValue));
			}}
		/>;
};

function decimalToDegree(value?: number): string {
	if (value == null || Number.isNaN(value)) return '';
	const negative: boolean = value < 0;
	value = Math.abs(value);

	let deg: number = Math.floor(value);
	const minFloat: number = (value - deg) * 60;
	let min: number = Math.floor(minFloat);
	const secFloat: number = (minFloat - min) * 60;
	let sec: number = Math.round(secFloat);

	// rounding
	if (sec == 60) {
		min++;
		sec = 0;
	}
	if (min == 60) {
		deg++;
		min = 0;
	}

	return `${negative ? '-' : ''}${deg}° ${min}' ${sec}"`;
}

function degreeToDecimal(value?: string): number {
	const regex = /^\s*(-)?([0-9]*)°(\s*[0-5]?[0-9]')?(\s*[0-5]?[0-9]([,.][0-9]+)?")?$/;
	const regexRes = regex.exec(value || '');
	if (regexRes == null) return NaN;

	let decimal = Number(regexRes[2]);
	if (regexRes[3]) decimal += Number(regexRes[3].replace('\'', '')) / 60;
	if (regexRes[4]) decimal += Number(regexRes[4].replace('"', '')) / 3600;
	if (regexRes[1]) decimal *= -1;

	return decimal;
}

export default DegreeInput;
