import React from 'react';
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';
import Table, { Column, FetchPageAsPromise, DateColumn, TableToolbarActions } from '../../reusable/Table';
import { Visibility } from '@material-ui/icons';
import { getBuoyShowRoute } from './BuoyRouter';
import { useHistory } from 'react-router-dom';
import { buoyApi, Buoy } from '../../../api/buoy.api';

const useBuoyListStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden'
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto'
	}
});

export const BuoyList = () => {
	const classes = useBuoyListStyles();
	const history = useHistory();

	const getPage: FetchPageAsPromise<any> = React.useCallback((pagingOpts, sortOpts) => {
		return buoyApi.find(undefined, pagingOpts, sortOpts ? { [sortOpts.sortBy]: sortOpts.sortDir } : { lastMessageAt: 'ASC' });
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.tableContainer}>
				<Table fetchTablePageFn={getPage} options={{ title: 'Bove', defaultPageSize: 25 }}
					toolbarActions={
						<TableToolbarActions labels={{ refresh: 'Osvježi' }} />
					}
				>
					<Column field='name' label='Ime' />
					<Column field='latitude' label='Geografska širina' sortBy={false} />
					<Column field='longitude' label='Geografska dužina' sortBy={false} />
					<Column field='deviceKey' label='DevEUI' />
					<DateColumn field='lastMessageAt' label='Vrijeme zadnje poruke' locale='hr' type='datetime' />
					<Column label='Status' sortBy='status' >
						{(row: Buoy) => <>{row.status || '/'}</>}
					</Column>
					<Column label='Baterija [%]' sortBy='batteryPercentage'>
						{(row: Buoy) => <>{row.batteryPercentage ? `${row.batteryPercentage}%` : '/'}</>}
					</Column>
					<Column label='Temperatura [°C]' sortBy='temperature'>
						{(row: Buoy) => <>{row.temperature ? `${row.temperature}°C` : '/'}</>}
					</Column>
					<Column width={'5%'}>
						{(row: Buoy, _isRowSelected, _toggleRowSelection, _refreshPage) => {
							return (
								<div style={{
									display: 'flex',
									justifyContent: 'flex-end'
								}}>
									<Tooltip title='Prikaži'>
										<IconButton onClick={_ => {
											history.push(getBuoyShowRoute(row.id));
										}}>
											<Visibility />
										</IconButton>
									</Tooltip>
								</div>
							);
						}}
					</Column>
				</Table>
			</div>
		</div>
	);
};
