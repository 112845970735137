import * as Yup from 'yup';
import { BuoyDeviceAttributes } from '../../../../../api/buoy.api';
import { BuoyGpsConfigurationMsg, BuoyMsgType } from '../../../../../api/buoy/buoy-downlink';

export interface BuoyGpsSettingsFormModel {
	gpsNavigationMode?: 'normal' | 'stationary';
	lockTimeout?: number;
	operatingMode?: 'max_hdop' | 'max_hepe' | 'no_movement';
	// max hdop
	targetHDOP?: number;
	extraTime?: number;
	// max hepe
	targetHEPE?: number;
	// no movement
	maxDistance?: number;
	distanceRepetitions?: number;
}


export const OperatingModeOpts = {
	'Max HEPE': 'max_hepe',
	'Max HDOP': 'max_hdop',
	'Bez značajnog odstupanja': 'no_movement'
};

export const GpsNavigationModeOpts = {
	'Normalni': 'normal',
	'Statičan': 'stationary'
};

export const toBuoyGpsSettingsForm = (attributes: BuoyDeviceAttributes): BuoyGpsSettingsFormModel => {
	let gpsNavigationMode: any;
	if (attributes.gps_navigation_mode === 'normal'
		|| attributes.gps_navigation_mode === 'stationary') gpsNavigationMode = attributes.gps_navigation_mode;

	return {
		distanceRepetitions: attributes.gps_distance_repetitions,
		extraTime: attributes.gps_extra_time_sec,
		gpsNavigationMode,
		lockTimeout: attributes.gps_lock_timeout,
		maxDistance: attributes.gps_max_distance,
		operatingMode: attributes.gps_operating_mode,
		targetHDOP: attributes.target_hdop,
		targetHEPE: attributes.target_hepe
	};
};

export const toBuoyGpsConfigurationMsg: (form: BuoyGpsSettingsFormModel) => BuoyGpsConfigurationMsg = ({
	distanceRepetitions, extraTime, gpsNavigationMode, lockTimeout, maxDistance, operatingMode,
	targetHDOP, targetHEPE
}) => {
	return {
		type: BuoyMsgType.GpsConfiguration,
		targetHDOP: operatingMode !== 'max_hdop' ? undefined : targetHDOP,
		extraTime: operatingMode !== 'max_hdop' ? undefined : extraTime,
		targetHEPE: operatingMode !== 'max_hepe' ? undefined : targetHEPE,
		maxDistance: operatingMode !== 'no_movement' ? undefined : maxDistance,
		distanceRepetitions: operatingMode != 'no_movement' ? undefined : distanceRepetitions,
		gpsNavigationMode,
		lockTimeout,
		operatingMode
	};
};

export const buoyGpsSettingsValidationSchema = Yup.object().shape<BuoyGpsSettingsFormModel>({
	targetHEPE: Yup.number()
		.typeError('Vrijednost mora biti broj')
		.min(1, 'Min vrijednost je 1')
		.max(255, 'Max vrijednost je 255'),
	targetHDOP: Yup.number()
		.typeError('Vrijednost mora biti broj')
		.min(1, 'Min vrijednost je 1')
		.max(25.5, 'Max vrijednost je 255'),
	distanceRepetitions: Yup.number()
		.typeError('Vrijednost mora biti cijeli broj')
		.integer('Vrijednost mora biti cijeli broj')
		.min(1, 'Min vrijednost je 1')
		.max(255, 'Max vrijednost je 255'),
	extraTime: Yup.number()
		.typeError('Vrijednost mora biti cijeli broj')
		.integer('Vrijednost mora biti cijeli broj')
		.min(1, 'Min vrijednost je 1')
		.max(255, 'Max vrijednost je 255'),
	gpsNavigationMode: Yup.string()
		.oneOf(Object.values(GpsNavigationModeOpts), `Mora biti jedna od sljedecih vrijednosti: ${GpsNavigationModeOpts}`) as any,
	operatingMode: Yup.string()
		.oneOf(Object.values(OperatingModeOpts), `Mora biti jedna od sljedecih vrijednosti: ${OperatingModeOpts}`) as any,
	lockTimeout: Yup.number()
		.typeError('Vrijednost mora biti cijeli broj')
		.integer('Vrijednost mora biti cijeli broj')
		.min(5, 'Min vrijednost je 5')
		.max(255, 'Max vrijednost je 255'),
	maxDistance: Yup.number()
		.typeError('Vrijednost mora biti broj')
		.min(0.1, 'Min vrijednost je 0.1')
		.max(25.5, 'Max vrijednost je 25.5'),
});
