export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  RegionAdmin = 'REGION_ADMIN', // can only see stuff from regions assigned to
  ViewOnlyUser = 'VIEW_ONLY_USER' // can only see buoys assigned to
}

export interface User {
	id: string;
	email: string;
	role: string;
	password?: string;
	receiveMonthlyDigest: boolean;
	buoyIds: string[];
	createdAt: Date;
	updatedAt: Date;
}
