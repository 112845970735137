import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, FormControlLabel, InputAdornment, Switch, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { BuoyDeviceAttributes } from '../../../../../api/buoy.api';
import { BuoyConfigurationMsg } from '../../../../../api/buoy/buoy-downlink';
import { AsyncSelect } from '@telemetris/ui-components';
import { getFieldProps } from '../../../../utility/formik-helpers';
import { FormikTypeHack, TogglebleSettingName, OperatingModeOpts, AccAngleThresholdOpts, AccTiltTimeoutOpts, BuoyGeneralSettingsFormModel, toBuoyGeneralSettingsForm, toBuoyConfigurationMsg, buoyGeneralSettingsValidationSchema } from './BuoyGeneralSettings.type';

interface Props {
	onSend: (msg: BuoyConfigurationMsg) => void;
	onCancel?: () => void;
	buoyDeviceAttributes?: BuoyDeviceAttributes;
}

const useStyles = makeStyles({
	input: {
		width: '300px',
	},
	inputWithSlider: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
});

type SettingInputRenderFn = (disabled: boolean, className: string, formik: ReturnType<typeof FormikTypeHack>) => JSX.Element;
const SettingNameInputPairs: Record<TogglebleSettingName, SettingInputRenderFn> = {
	msgIntervalPhaseInit: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Inicijalni interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhaseInit)}
	/>,
	msgIntervalPhase1: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Faza 1 - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhase1)}
	/>,
	msgIntervalPhase2: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Faza 2 - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhase2)}
	/>,
	msgIntervalPhase3: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Faza 3 - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhase3)}
	/>,
	msgIntervalPhase4: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Faza 4 - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhase4)}
	/>,
	msgIntervalPhase5: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Faza 5 - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhase5)}
	/>,
	msgIntervalPhaseStandby: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Standby - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalPhaseStandby)}
	/>,
	msgIntervalIdle: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Idle - interval poruka'
		InputProps={{ endAdornment: <InputAdornment position="end">minuta</InputAdornment> }}
		{...getFieldProps(formik, TogglebleSettingName.MsgIntervalIdle)}
	/>,
	maxUnsentLogs: (disabled, className, formik) => <TextField
		disabled={disabled}
		className={className}
		type='number'
		label='Max broj neposlanih poruka'
		{...getFieldProps(formik, 'maxUnsentLogs')}
	/>,
	operatingMode: (disabled, className, formik) => <div style={{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px',
		padding: '10px',
		borderTop: '3px dotted #bbb',
		borderBottom: '3px dotted #bbb'
		}}>
		<AsyncSelect
			disabled={disabled}
			className={className}
			label='Način rada'
			allowEmpty
			getOptions={() => Promise.resolve(Object.entries(OperatingModeOpts).map(([key, value]) => ({ id: value, name: key })))}
			{...getFieldProps(formik, 'operatingMode')}
		/>
		{
			formik.values.operatingMode === 'manual' ? <FormControlLabel
					label='GPS Uključen'
					control={<Switch disabled={disabled} {...getFieldProps(formik, 'manualTriggerOn', 'checkbox')} />}
				/> : null
		}
		{
			formik.values.operatingMode === 'tilt_detection' ? <>
				<AsyncSelect
					disabled={disabled}
					className={className}
					label='Kutni prag akcelerometer'
					getOptions={() => Promise.resolve(Object.entries(AccAngleThresholdOpts).map(([key, value]) => ({ id: value, name: key })))}
					{...getFieldProps(formik, 'accAngleThreshold')}
				/>
				<AsyncSelect
					disabled={disabled}
					className={className}
					label='Accelerometer tilt timeout'
					getOptions={() => Promise.resolve(Object.entries(AccTiltTimeoutOpts).map(([key, value]) => ({ id: value, name: key })))}
					{...getFieldProps(formik, 'accTiltTimeout')}
				/>
			</> : null
		}
	</div>
};

export const BuoyGeneralSettings = (props: Props) => {
	const { onSend, onCancel, buoyDeviceAttributes } = props;
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	// TODO: cast is okay since undefined is practically same as false
	const [settingToggles, setSettingToggles] = React.useState<Record<TogglebleSettingName, boolean>>({
		msgIntervalPhase1: false,
		msgIntervalPhaseInit: false,
		msgIntervalPhase2: false,
		msgIntervalPhase3: false,
		msgIntervalPhase4: false,
		msgIntervalPhase5: false,
		operatingMode: false,
		msgIntervalPhaseStandby: false,
		msgIntervalIdle: false,
		maxUnsentLogs: false
	});

	const formik = useFormik<BuoyGeneralSettingsFormModel>({
		initialValues: buoyDeviceAttributes ? toBuoyGeneralSettingsForm(buoyDeviceAttributes) : toBuoyGeneralSettingsForm(),
		onSubmit: (form: BuoyGeneralSettingsFormModel) => {
			// we are going to modify in place -> so let's create deep copy of formik's values
			const values: BuoyGeneralSettingsFormModel = JSON.parse(JSON.stringify(form));
			// remove untoggled properties
			for (const [settingName, toggled] of Object.entries(settingToggles)) {
				if (!toggled) {
					(values as any)[settingName] = undefined;
				}
			}
			if (!settingToggles.operatingMode) {
				values.manualTriggerOn = undefined;
				values.accAngleThreshold = undefined;
				values.accTiltTimeout = undefined;
			}

			if (Object.values(values).every(val => val === undefined)) {
				enqueueSnackbar('Postavite neke postavke prije slanja poruke...', { variant: 'info' });
				return;
			}
			onSend(toBuoyConfigurationMsg(values));
		},
		validationSchema: buoyGeneralSettingsValidationSchema,
	});

	return (
		<div>
			{/* <BuoyGeneralSettingsForm formik={formik} /> */}
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				{
					Object.entries(SettingNameInputPairs).map(([settingName, renderSettingInputFn]) => {
						// NOTE: enum TogglebleSettingName iteration loses type: TogglebleSettingName -> string
						const key: TogglebleSettingName = settingName as any;
						const toggleComponent = <Switch
							value={settingToggles[key]}
							// track toggled off settings
							onChange={({ target: { checked } }) => setSettingToggles({
								...settingToggles,
								[settingName]: checked
							})} />;

						return <div className={classes.inputWithSlider}>
							{toggleComponent}
							{renderSettingInputFn(!settingToggles[key], classes.input, formik)}
						</div>;
					})
				}
			</div>
			<div style={{ marginTop: '30px' }}>
				<Button
					style={{ margin: '10px 0 16px 0' }}
					onClick={() => formik.handleSubmit()}
					variant='contained'
					color='primary'>
					Primjeni
				</Button>
				<Button
					style={{ margin: '10px 0 16px 10px' }}
					onClick={() => onCancel ? onCancel() : null}
					variant='contained'>
					Otkaži
				</Button>
			</div>
		</div>
	);
};
