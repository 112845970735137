import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

export const getTextFieldProps = (formik: FormikHandlers, key: string) => {
	const errorMessage: string = formikErrorToString(formik.getFieldMeta(key).error);
	const touched = !!formik.getFieldMeta(key).touched;
	return {
		...formik.getFieldProps(key),
		error: touched && !!errorMessage,
		helperText: touched ? errorMessage : '',
	};
};

export const getFieldProps = (formik: FormikHandlers & FormikHelpers<any>, key: string, type: 'text' | 'checkbox' = 'text') => {
	const errorMessage: string = formikErrorToString(formik.getFieldMeta(key).error);
	const touched = !!formik.getFieldMeta(key).touched;
	return {
		...formik.getFieldProps({ name: key, type }),
		onChange: (e: React.ChangeEvent | any) => {
			if (e?.target) {
				formik.handleChange(e);
			} else {
				formik.setFieldValue(key, e);
			}
		},
		error: touched && !!errorMessage,
		helperText: touched ? errorMessage : '',
	};
};

function getFirstNonEmptyElement(array: any[]) {
	for (const elem of array) {
		if (elem !== undefined) return elem;
	}
}

export function formikErrorToString(error: FormikErrors<any>[] | FormikErrors<any> | string[] | string | undefined): string {
	error = error || '';
	if (Array.isArray(error)) {
		error = error.length ? getFirstNonEmptyElement(error) : '';
	}
	if (typeof error === 'object') {
		return Object.values(error).length ? Object.values(error)[0] as string : '';
	} else {
		return error || '';
	}
}
