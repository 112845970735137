/** static resources */

export const TelemetrisLogo = '/assets/tm-logo-color.png';
export const BackgroundImg = '/assets/jadransko_more.jpg';
export const EuLogo = '/assets/eu-logos.png';
export const DarkThemeEuLogos = '/assets/eu-logos-dark-theme.png';
export const OiVSmartinoLogo = '/assets/OIV_Smartino_logo.png';
export const SmallOiVSmartinoLogo = '/assets/small_smartino_logo.png';
export const DarkThemeSmallOiVSmartinoLogo = '/assets/dark_theme-small_smartino_logo.png';
export const DarkThemeOiVSmartinoLogo = '/assets/OIV_Smartino_logo-dark-theme.png';
export const MinistarstvoPoljoprivredeLogo = '/assets/ministarstvo_poljoprivrede_logo.png';
export const DarkThemeMinistarstvoPoljoprivredeLogo = '/assets/dark_theme-ministarstvo_poljoprivrede_logo.png';

export const EuLogoDisclaimerText = 'Pilot projekt se sufinancira sredstvima Europske unije iz Europskog fonda za pomorstvo i ribarstvo';