import * as Yup from 'yup';

export interface BuoyPairAlarmFormModel {
	distanceThreshold: number;
}

export const defaultBuoyPairAlarmForm: () => BuoyPairAlarmFormModel = () => ({ distanceThreshold: 0 });

export const buoyPairAlarmValidationSchema = Yup.object().shape<BuoyPairAlarmFormModel>({
	distanceThreshold: Yup
		.number()
		.min(50, 'Minimalna udaljenost je 50m')
		.required('Unesite maksimalnu udaljenost'),
});
