import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, IconButton, Tooltip, Popover, MenuItem, List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import { AccountCircle, WbSunny, NightsStay, PowerSettingsNew } from '@material-ui/icons';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSnackbar } from 'notistack';
import { SmallOiVSmartinoLogo, DarkThemeSmallOiVSmartinoLogo, EuLogoDisclaimerText, EuLogo, DarkThemeEuLogos } from '../../../static';
import { ThemeType, useTheme } from '../../contexts/theme/ThemeContext';
import { Drawer, DrawerAnchor } from '../reusable/Drawer';
import { useIsPortrait } from '../utility/responsive';
import { useUserRole } from '../utility/useUserRole';

interface TopbarMenuItem {
	label: string;
	icon: JSX.Element;
	route: string;
}

interface TopbarProps {
	children: JSX.Element;

	/**
	 * Define content on you own.
	 */
	content?: JSX.Element;

	logo?: JSX.Element;
	menuItems?: TopbarMenuItem[];
	username?: string;
	onLogout?: () => void;
}

const topbarHeight = '42px';

const useTopbarStyles = makeStyles({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	topbarContentContainer: {
		width: '100%',
		height: topbarHeight,
		boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
		zIndex: 11
	},
	childrenContainer: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto'
	},
	topbarContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '100%',
		width: '100%'
	},
	tabs: {
		minHeight: '42px',
		height: '42px'
	},
	tab: {
		minWidth: '60px',
		minHeight: '42px',
		height: '42px'
	},
	label: {
		marginLeft: 10
	},
	logoSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	leftSection: {
		display: 'flex',
		flexDirection: 'row'
	},
	rightSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginRight: 15,
		'& > *': {
			marginLeft: 14
		}
	},
	popover: {
		pointerEvents: 'none',
	},
	popoverContent: {
		pointerEvents: 'auto',
	},
	notice: {
		textAlign: 'left',
		maxWidth: '300px',
		marginLeft: '20px',
		fontSize: '13px',
		fontWeight: 200
	},
	euLogo: {
		paddingBottom: '6px',
		marginRight: '10px'
	}
});

export const Topbar = (props: TopbarProps) => {
	// responsive stuff
	const isPortrait = useIsPortrait();
	const isShortEnough = useMediaQuery({ query: '(max-width: 1850px)' });
	const classes = useTopbarStyles();
	const history = useHistory();
	const location = useLocation();
	const { theme, setTheme } = useTheme();
	const { isSuperAdmin } = useUserRole();
	const { enqueueSnackbar } = useSnackbar();
	const [syncingBuoys, setSyncingBuoys] = React.useState(false);

	const menuItems = props.menuItems || [];

	const [tab, setTab] = React.useState(0);
	React.useEffect(() => {
		const selectedTab = menuItems.findIndex(item => matchPath(location.pathname, item.route));
		setTab(selectedTab);
	}, [location, menuItems]);
	const handleTabChange = React.useCallback((_event, newTab) => setTab(newTab), []);

	// user popover for account button menu, should extract this behavior
	const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLDivElement) | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);

	return (
		<div className={classes.container}>
			<div className={classes.topbarContentContainer}>
				<div className={classes.topbarContent}>
					<div className={classes.rightSection}>
						<div className={classes.logoSection}
							onClick={() => setMenuDrawerOpen(true)}>
							<img src={theme.type === ThemeType.Light ? SmallOiVSmartinoLogo : DarkThemeSmallOiVSmartinoLogo} height={20} alt='logo' />
						</div>
						<Drawer overlay open={menuDrawerOpen} onClose={() => setMenuDrawerOpen(false)} anchor={DrawerAnchor.Left} zIndex={100}>
							{() => <>
								<List component="nav">
									{
										menuItems.map(item => (
											<ListItem
												selected={!!matchPath(location.pathname, item.route)}
												key={item.route} button
												onClick={_ => {
													history.push(item.route);
													setMenuDrawerOpen(false);
												}}>
												<ListItemIcon style={{ minWidth: '40px' }}>
													{item.icon}
												</ListItemIcon>
												<ListItemText primary={item.label} />
											</ListItem>
										))
									}
								</List>
								<Divider />
								<List component="nav">
									<ListItem>
										<ListItemText primary={props.username} />
									</ListItem>
								</List>
								<Divider />
							</>}
						</Drawer>
						{
							!isPortrait &&
							<Tabs
								classes={{ root: classes.tabs }}
								value={tab}
								indicatorColor="primary"
								textColor="primary"
								onChange={handleTabChange}
							>
								{
									menuItems.map(item => {
										const tab = <Tab
											key={item.route}
											onClick={_ => history.push(item.route)}
											label={<div>
												{item.icon}
												{isShortEnough || <span className={classes.label}>{item.label}</span>}
											</div>}
											classes={{ root: classes.tab }}
										/>;

										return isShortEnough ? <Tooltip title={item.label}>{tab}</Tooltip> : tab;
									})
								}
							</Tabs>
						}
					</div>
					<div className={classes.rightSection}>
						<div
							onClick={handleClick}
						>
							<IconButton>
								<AccountCircle style={{ fontSize: 24 }} />
							</IconButton>
						</div>
						{
							theme.type === ThemeType.Light ? (
								<Tooltip title='Tamna tema'>
									<IconButton onClick={_ => setTheme({ ...theme, type: ThemeType.Dark })}>
										<NightsStay style={{ fontSize: 24 }} />
									</IconButton>
								</Tooltip>
							) : (
									<Tooltip title='Svijetla tema'>
										<IconButton onClick={_ => setTheme({ ...theme, type: ThemeType.Light })}>
											<WbSunny style={{ fontSize: 24 }} />
										</IconButton>
									</Tooltip>
								)
						}
					</div>
				</div>
				<Popover
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					onClose={handleClose}
					open={!!anchorEl}
				>
					<div style={{
						padding: '12px',
						fontSize: 14
					}}>{props.username}</div>
					<MenuItem onClick={_ => history.push('/login')}>
						<div onClick={_ => props.onLogout ? props.onLogout() : null}>
							<PowerSettingsNew style={{ verticalAlign: 'middle' }} fontSize={'small'} />
							<span className={classes.label}>Odjava</span>
						</div>
					</MenuItem>
				</Popover>
			</div>
			<div className={classes.childrenContainer}>
				{props.children}
			</div>
		</div>
	);
};
