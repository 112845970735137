import React, { useState, useCallback } from 'react';
import Table, { Column, DateColumn, FetchPageAsPromise, TableToolbarActions, TableToolbarActionsProps } from '../../../reusable/Table';
import { buoyApi, BuoyDownlinkAuditLog, BuoyGeolocationPoint } from '../../../../api/buoy.api';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { subMonths, startOfDay, endOfDay, isValid } from 'date-fns';
import { Visibility } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BuoyMsgTypeTranslation } from '../buoy.translations';
import { BuoyDownlinkAuditLogMsgDialog } from './BuoyDownlinkAuditLogMsgDialog';
import { BuoyDownlinkMsg, BuoyMsgType } from '../../../../api/buoy/buoy-downlink';
import hrLocale from 'date-fns/locale/hr';

interface TableToolbarProps extends TableToolbarActionsProps {
	from: Date;
	setFrom: React.Dispatch<React.SetStateAction<Date>>;
	to: Date;
	setTo: React.Dispatch<React.SetStateAction<Date>>;
}

const TableToolbar = (props: TableToolbarProps) => {
	const { from, setFrom, to, setTo } = props;

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
				<KeyboardDatePicker
					disableFuture
					label="Od"
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					value={from}
					onChange={(date: Date | null) => date && isValid(date) && setFrom(startOfDay(date))}
					style={{ marginRight: '10px' }}
				/>
			</MuiPickersUtilsProvider>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
				<KeyboardDatePicker
					disableFuture
					label="Do"
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					value={to}
					onChange={(date: Date | null) => date && isValid(date) && setTo(endOfDay(date))}
					style={{ marginRight: '10px' }}
				/>
			</MuiPickersUtilsProvider>
			<TableToolbarActions {...props} labels={{ refresh: 'Osvježi' }} />
		</>
	);
};

const useStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden',
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto',
	},
});

interface Props {
	buoyId: string;
}

export const BuoyDownlinkAuditLogTable = (props: Props) => {
	const { buoyId } = props;
	const [from, setFrom] = useState<Date>(startOfDay(subMonths(new Date(), 1)));
	const [to, setTo] = useState<Date>(endOfDay(new Date()));
	const classes = useStyles();
	const [buoyMessageDialogOpen, setBuoyMessageDialogOpen] = React.useState(false);
	const [buoyDownlinkMsg, setBuoyDownlinkMsg] = React.useState<BuoyDownlinkMsg | undefined>(undefined);

	const getPage: FetchPageAsPromise<BuoyDownlinkAuditLog> = useCallback(
		(pagingOpts) => {
			return buoyApi.getBuoyDownlinkAuditLogs(buoyId, from, to, pagingOpts);
		},
		[buoyId, from, to]
	);

	return (
		<>
			<div className={classes.container}>
				<div className={classes.tableContainer}>
					<Table
						fetchTablePageFn={getPage}
						options={{
							title: 'Pregled zakazanih poruka',
							defaultPageSize: 5,
							pageSizeOptions: [5, 10, 25, 50],
						}}
						toolbarActions={<TableToolbar from={from} setFrom={setFrom} to={to} setTo={setTo} />}
					>
						<Column label="Poslao" field="sentBy" />
						<Column label="Tip poruke" field="type">
							{(row: BuoyDownlinkAuditLog) => <>{BuoyMsgTypeTranslation[row.sentMsg.type]}</>}
						</Column>
						<DateColumn label="Vrijeme poruke" field="sentAt" locale="hr" />
						<Column label="">
							{(row: BuoyDownlinkAuditLog) => {
								// dialog only for config and gps config msgs
								if ([BuoyMsgType.Configuration, BuoyMsgType.GpsConfiguration].some(type => row.sentMsg.type === type)) {
									return (
										<IconButton
											onClick={() => {
												setBuoyDownlinkMsg(row.sentMsg);
												setBuoyMessageDialogOpen(true);
											}}
										>
											<Visibility />
										</IconButton>
									);
								}
								return <></>;
							}}
						</Column>
					</Table>
				</div>
			</div>
			<BuoyDownlinkAuditLogMsgDialog message={buoyDownlinkMsg} open={buoyMessageDialogOpen} onClose={() => setBuoyMessageDialogOpen(false)} />
		</>
	);
};
