import React from 'react';
import { makeStyles, Tooltip, Link } from '@material-ui/core';
import Table, { Column, DateColumn, FetchPageAsPromise, TableToolbarActions } from '../../reusable/Table';
import { useHistory } from 'react-router-dom';
import { getBuoyShowRoute } from '../../views/buoys/BuoyRouter';
import { Alarm, alarmApi, AlarmType } from '../../../api/alarm.api';
import axios from 'axios';


const useListStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden',
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto'
	},
	actionsCell: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
});

export const AlarmList = () => {
	const classes = useListStyles();
	const history = useHistory();

	const getAlarmPage: FetchPageAsPromise<Alarm> = React.useCallback(async ({ size, page }) => {
		const { data: alarms } = await axios.get('/api/alarm');
		alarms.sort((x: any, y: any) => new Date(y.at).getTime() - new Date(x.at).getTime());
		const [startIdx, endIdx] = [page*size, (page + 1)*size];
		return {
			total: alarms.length,
			data: alarms.slice(startIdx, endIdx)
		};
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.tableContainer}>
				<Table fetchTablePageFn={getAlarmPage} options={{ title: 'Alarmi', defaultPageSize: 25 }}
					toolbarActions={
						<TableToolbarActions labels={{ create: 'Alarmi', refresh: 'Osvježi' }} />
					}
				>
					<Column label='Tip alarma'>
						{(row: any) => {
							return <div>PREKORAČENA UDALJENOST</div>;
						}}
					</Column>

					<DateColumn field='at' label='Vrijeme' locale='hr' />

					<Column label='Master'>
						{(row: any) => {
							return <div>{row.masterDeviceKey}</div>;
						}}
					</Column>

					<Column label='Master'>
						{(row: any) => {
							return <div>{row.slaveDeviceKey}</div>;
						}}
					</Column>

					<Column label='Udaljenost'>
						{(row: any) => {
							return <div>{row.distance.toFixed(2)} m</div>;
						}}
					</Column>
				</Table>
			</div>
		</div>
	);
};
