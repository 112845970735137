import 'reflect-metadata';
import Axios from 'axios';
import { Type } from 'class-transformer';
import { IsArray, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator';
import { authService } from './auth.api';
import { createFilterBuilder, Filter } from './reusable/filters';
import { RestCrudApi } from './reusable/generic-rest-crud';

export interface Attribute {
	id: string;
	definitionId: string;
	value: string;
}

export class AttrUpdateForBuoy {
	@IsUUID()
	@IsOptional()
	id?: string;

	@IsUUID()
	definitionId!: string;

	@IsString()
	value!: string;
}

export interface AttrDefSet {
	id: string;
	name: string;
	createdAt: Date;
	updatedAt: Date;
}

export class AttrDefUpdateForSet {
	@IsUUID()
	@IsOptional()
	id?: string;

	@IsString()
	name!: string;

	@IsString()
	@IsOptional()
	description?: string;
}

export class AttrDefSetCreate {
	@IsString()
	name!: string;

	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => AttrDefUpdateForSet)
	definitions!: AttrDefUpdateForSet[];
}

export class AttrDefSetUpdate {
	@IsUUID()
	id!: string;

	@IsString()
	name!: string;

	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => AttrDefUpdateForSet)
	definitions!: AttrDefUpdateForSet[];
}

export interface AttrDef {
	id: string;
	name: string;
	description?: string;
	setId: string;
	createdAt: Date;
	updatedAt: Date;
}

declare const ATTR_DEF_SET_FILTER_TAG: unique symbol;
export type AttrDefSetFilter = Filter & { readonly [ATTR_DEF_SET_FILTER_TAG]: 'ATTR_DEF_SET_FILTER' }

export enum AttrDefSetFilterName {
	Id = 'id',
	Name = 'name',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt'
}

export const Id = createFilterBuilder<string, AttrDefSetFilter>(AttrDefSetFilterName.Id);
export const Name = createFilterBuilder<string, AttrDefSetFilter>(AttrDefSetFilterName.Name);
export const CreatedAt = createFilterBuilder<Date, AttrDefSetFilter>(AttrDefSetFilterName.CreatedAt);
export const UpdatedAt = createFilterBuilder<Date, AttrDefSetFilter>(AttrDefSetFilterName.UpdatedAt);

const transformFromDto = (dto: any): AttrDefSet => {
	return {
		...dto,
		createdAt: new Date(dto.createdAt),
		updatedAt: new Date(dto.updatedAt),
	};
};

class AttrDefSetApi extends RestCrudApi<AttrDefSetFilter, AttrDefSet, AttrDefSetCreate, AttrDefSetUpdate> {
	constructor() {
		super('/api/attribute-definition-sets', authService, transformFromDto);
	}

	getDefinitions(id: string): Promise<AttrDef[]> {
		const url = `${this.baseUrl}/${id}/definitions`;
		return Axios.get(url, this.constructHeaders()).then(res => res.data);
	}
}

export const attrDefSetApi = new AttrDefSetApi();
