import { IsString, IsIn, IsOptional, IsInt, IsBoolean, IsNumber, IsNotEmpty } from 'class-validator';

export enum BuoyMsgType {
	Configuration = 'configuration',
	GpsConfiguration = 'gpsConfiguration',
	GeoTrigger = 'geoTrigger',
	PowerOff = 'powerOff',
	ChangePassword = 'changePassword',
}

export class BuoyConfigurationMsg {
	@IsString()
	@IsIn([BuoyMsgType.Configuration])
	type!: BuoyMsgType.Configuration;

	@IsString()
	@IsOptional()
	@IsIn(['tilt_detection', 'manual'])
	operatingMode?: 'manual' | 'tilt_detection';

	@IsInt()
	@IsOptional()
	msgIntervalPhaseInit?: number;

	@IsInt()
	@IsOptional()
	msgIntervalPhase1?: number;

	@IsInt()
	@IsOptional()
	msgIntervalPhase2?: number;

	@IsInt()
	@IsOptional()
	msgIntervalPhase3?: number;

	@IsInt()
	@IsOptional()
	msgIntervalPhase4?: number;

	@IsInt()
	@IsOptional()
	msgIntervalPhase5?: number;

	@IsInt()
	@IsOptional()
	msgIntervalPhaseStandby?: number;

	@IsInt()
	@IsOptional()
	msgIntervalIdle?: number;

	@IsBoolean()
	@IsOptional()
	manualTriggerOn?: boolean;

	@IsInt()
	@IsOptional()
	@IsIn([50, 60, 70, 80, 100, 120, 140, 160])
	accAngleThreshold?: number;

	@IsInt()
	@IsOptional()
	@IsIn([0, 30, 60, 180, 300])
	accTiltTimeout?: number;

	@IsInt()
	@IsOptional()
	maxUnsentLogs?: number;
}

export class BuoyGpsConfigurationMsg {
	@IsString()
	@IsIn([BuoyMsgType.GpsConfiguration])
	type!: BuoyMsgType.GpsConfiguration;

	@IsString()
	@IsOptional()
	@IsIn(['max_hdop', 'max_hepe', 'no_movement'])
	operatingMode?: 'max_hdop' | 'max_hepe' | 'no_movement';

	@IsString()
	@IsOptional()
	@IsIn(['normal', 'fitness', 'aviation', 'baloon', 'stationary'])
	gpsNavigationMode?: 'normal' | 'fitness' | 'aviation' | 'baloon' | 'stationary';

	// min, max validation!
	@IsNumber()
	@IsOptional()
	lockTimeout?: number;

	@IsNumber()
	@IsOptional()
	targetHDOP?: number;

	@IsNumber()
	@IsOptional()
	targetHEPE?: number;

	@IsNumber()
	@IsOptional()
	extraTime?: number;

	@IsNumber()
	@IsOptional()
	maxDistance?: number;

	@IsInt()
	@IsOptional()
	distanceRepetitions?: number;
}

export class BuoyGeoTriggerMsg {
	@IsString()
	@IsIn([BuoyMsgType.GeoTrigger])
	type!: BuoyMsgType.GeoTrigger;
}

export class BuoyPowerOffMsg {
	@IsString()
	@IsIn([BuoyMsgType.PowerOff])
	type!: BuoyMsgType.PowerOff;

	@IsOptional()
	@IsBoolean()
	factoryReset?: boolean;
}

export class BuoyChangePasswordMsg {
	@IsString()
	@IsIn([BuoyMsgType.ChangePassword])
	type!: BuoyMsgType.ChangePassword;

	@IsString()
	@IsNotEmpty()
	password!: string;
}

export type BuoyDownlinkMsg = BuoyConfigurationMsg | BuoyGpsConfigurationMsg | BuoyGeoTriggerMsg | BuoyPowerOffMsg | BuoyChangePasswordMsg;
