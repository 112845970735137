import React from 'react';
import { makeStyles, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useFormik } from 'formik';
import { BuoyChangePasswordFormModel } from './BuoyChangePassword.type';
import { getTextFieldProps } from '../../../../utility/formik-helpers';

const useStyles = makeStyles({
	input: {
		width: '300px',
	},
});

const FormikTypeHack = () => {
	return useFormik<BuoyChangePasswordFormModel>({} as any);
};

interface Props {
	formik: ReturnType<typeof FormikTypeHack>;
}

export const BuoyChangePasswordForm = (props: Props) => {
	const { formik } = props;
	const classes = useStyles();
	const [passwordVisible, setPasswordVisible] = React.useState(false);
	const [pConfirmationVisible, setPConfirmationVisible] = React.useState(false);

	return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
		<TextField
			className={classes.input}
			label='Šifra'
			type={passwordVisible ? 'text' : 'password'}
			InputProps={{
				endAdornment: <InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={_ => setPasswordVisible(!passwordVisible)}
					>
						{passwordVisible ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</InputAdornment>
			}}
			onFocus={e => e.target.select()}
			{...getTextFieldProps(formik, 'password')}
		/>
		<TextField
			className={classes.input}
			label='Potvrda šifre'
			type={pConfirmationVisible ? 'text' : 'password'}
			InputProps={{
				endAdornment: <InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={_ => setPConfirmationVisible(!pConfirmationVisible)}
					>
						{pConfirmationVisible ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</InputAdornment>
			}}
			onFocus={e => e.target.select()}
			{...getTextFieldProps(formik, 'passwordConfirmation')}
		/>
	</div>;
};
