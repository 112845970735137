import Axios from 'axios';
import { GenericAuthService } from './generic-auth-service';
import { Filter, Page, Paging, Sorting, toQueryParams } from './filters';

export class RestCrudApi<SomeFilter extends Filter, View, Create, Update> {
	constructor(protected readonly baseUrl: string, protected readonly authService?: GenericAuthService<any>, protected readonly transformFromDto: (dto: any) => View = a => a) {}

	protected constructHeaders() {
		return this.authService ? { headers: { Authorization: `Bearer ${this.authService.getAccessToken()}` } } : {};
	}

	find(filter?: SomeFilter, paging?: Paging, sorting?: Sorting): Promise<Page<View>> {
		const filterQPs = filter ? toQueryParams(filter) : '';
		const pagingQPs = paging ? `page=${paging.page}&size=${paging.size}` : '';
		// TODO: multiple sorting?
		let sortingQP;
		if (sorting) {
			const sortKey = Object.keys(sorting)[0];
			const sortVal = sorting[sortKey] === 'ASC' ? `asc:${sortKey}` : `desc:${sortKey}`;
			sortingQP = `sort=${sortVal}`;
		}
		const queryParams = [filterQPs, pagingQPs, sortingQP].filter(Boolean).join('&');
		return Axios.get(`${this.baseUrl}?${queryParams}`, this.constructHeaders())
			.then(data => data.data)
			.then(({ data, total }: Page<any>) => {
				return {
					data: data.map(dto => this.transformFromDto(dto)),
					total,
				};
			});
	}

	get(id: string): Promise<View> {
		return Axios.get(`${this.baseUrl}/${id}`, this.constructHeaders())
			.then(data => data.data)
			.then(data => this.transformFromDto(data));
	}

	getMany(ids: string[]): Promise<View[]> {
		return Axios.post(`${this.baseUrl}/get-bulk`, ids, this.constructHeaders())
			.then(data => data.data)
			.then((dtos: any[]) => dtos.map(dto => this.transformFromDto(dto)));
	}

	create(update: Create): Promise<View> {
		return Axios.post(`${this.baseUrl}`, update, this.constructHeaders())
			.then(data => data.data)
			.then(data => this.transformFromDto(data));
	}

	update(update: Update): Promise<View> {
		return Axios.patch(`${this.baseUrl}`, update, this.constructHeaders())
			.then(data => data.data)
			.then(data => this.transformFromDto(data));
	}

	delete(id: string): Promise<void> {
		return Axios.delete(`${this.baseUrl}/${id}`, this.constructHeaders())
			.then(data => data.data);
	}

	deleteMany(ids: string[]): Promise<void> {
		return Axios.delete(`${this.baseUrl}`, { ...this.constructHeaders(), data: ids })
			.then(data => data.data);			
	}
}

export class RestOneToMany<CustomFilter extends Filter, View> {

	constructor(
		protected readonly baseUrl: string,
		protected readonly subRoute: string,
		protected readonly authService?: GenericAuthService<any>,
		protected readonly transformFromDto: (dto: any) => View = a => a
	) {}

	protected constructHeaders() {
		return this.authService ? { headers: { Authorization: `Bearer ${this.authService.getAccessToken()}` } } : {};
	}

	find(parentId: string, filter?: CustomFilter, paging?: Paging, _sorting?: Sorting): Promise<Page<View>> {
		const filterQPs = filter ? toQueryParams(filter) : '';
		const pagingQPs = paging ? `page=${paging.page}&size=${paging.size}` : '';
		const queryParams = [filterQPs, pagingQPs].filter(Boolean).join('&');
		return Axios.get(`${this.baseUrl}/${parentId}/${this.subRoute}?${queryParams}`, this.constructHeaders())
			.then(data => data.data)
			.then(({ data, total }: Page<View>) => {
				return {
					data: data.map(dto => this.transformFromDto(dto)),
					total,
				};
			});
	}

	add(parentId: string, id: string): Promise<void> {
		return Axios.post(`${this.baseUrl}/${parentId}/${this.subRoute}/add/${id}`, undefined, this.constructHeaders())
			.then(data => data.data);
	}

	addMany(parentId: string, ids: string[]): Promise<void> {
		return Axios.post(`${this.baseUrl}/${parentId}/${this.subRoute}/add`, ids, this.constructHeaders())
			.then(data => data.data);
	}

	remove(parentId: string, id: string): Promise<void> {
		return Axios.delete(`${this.baseUrl}/${parentId}/${this.subRoute}/remove/${id}`, { headers: this.constructHeaders() })
			.then(data => data.data);
	}

	removeMany(parentId: string, ids: string[]): Promise<void> {
		return Axios.delete(`${this.baseUrl}/${parentId}/${this.subRoute}/remove`, { data: ids, headers: this.constructHeaders() })
			.then(data => data.data);
	}

	removeAll(parentId: string): Promise<void> {
		return Axios.delete(`${this.baseUrl}/${parentId}/${this.subRoute}/remove-all`, { headers: this.constructHeaders() })
			.then(data => data.data);
	}
}
