import * as Yup from 'yup';

export interface BuoyChangePasswordFormModel {
	password: string;
	passwordConfirmation: string;
}

export const defaultBuoyChangePasswordFormModel: () => BuoyChangePasswordFormModel = () => ({ password: '', passwordConfirmation: ''});

export const buoyChangePasswordValidationSchema = Yup.object().shape<BuoyChangePasswordFormModel>({
	password: Yup
		.string()
		.required('Šifra je obavezna'),
	passwordConfirmation: Yup
		.string()
		.oneOf([Yup.ref('password')], 'Potvrda šifre mora biti ista kao šifra')
		.required('Potvrda šifre je obavezna')
});
