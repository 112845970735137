/**
 * HACK: This hack is needed because chartjs-adapter-date-fns doesn't work with locales provided in options.scales.xAxes.
 * 
 * adapters: {
 *   date: {
 *     locale: hrLocale,
 *   },
 * }
 * 
 * This is a copy of chartjs-adapter-date-fns with hrLocale hardcoded.
 */

import * as Chartjs from 'chart.js';
import {
	parse, parseISO, toDate, isValid, format,
	startOfSecond, startOfMinute, startOfHour, startOfDay,
	startOfWeek, startOfMonth, startOfQuarter, startOfYear,
	addMilliseconds, addSeconds, addMinutes, addHours,
	addDays, addWeeks, addMonths, addQuarters, addYears,
	differenceInMilliseconds, differenceInSeconds, differenceInMinutes,
	differenceInHours, differenceInDays, differenceInWeeks,
	differenceInMonths, differenceInQuarters, differenceInYears,
	endOfSecond, endOfMinute, endOfHour, endOfDay,
	endOfWeek, endOfMonth, endOfQuarter, endOfYear
} from 'date-fns';
import hrLocale from 'date-fns/locale/hr';

const FORMATS = {
	datetime: 'MMM d, yyyy, h:mm:ss aaaa',
	millisecond: 'h:mm:ss.SSS aaaa',
	second: 'h:mm:ss aaaa',
	minute: 'h:mm aaaa',
	hour: 'ha',
	day: 'MMM d',
	week: 'PP',
	month: 'MMM yyyy',
	quarter: 'qqq - yyyy',
	year: 'yyyy'
};

(Chartjs as any)._adapters._date.override({
	_id: 'date-fns', // DEBUG

	formats: function() {
		return FORMATS;
	},

	parse: function(value: unknown, fmt: string) {
		if (value === null || typeof value === 'undefined') {
			return null;
		}
		if (typeof value === 'number' || value instanceof Date) {
			value = toDate(value);
		} else if (typeof value === 'string') {
			if (typeof fmt === 'string') {
				value = parse(value, fmt, new Date(), this.options);
			} else {
				value = parseISO(value, this.options);
			}
		}
		return isValid(value) ? (value as Date).getTime() : null;
	},

	format: function(time: number | Date, fmt: string) {
		return format(time, fmt, {locale: hrLocale});
	},

	add: function(time: number | Date, amount: number, unit: string) {
		switch (unit) {
		case 'millisecond': return addMilliseconds(time, amount);
		case 'second': return addSeconds(time, amount);
		case 'minute': return addMinutes(time, amount);
		case 'hour': return addHours(time, amount);
		case 'day': return addDays(time, amount);
		case 'week': return addWeeks(time, amount);
		case 'month': return addMonths(time, amount);
		case 'quarter': return addQuarters(time, amount);
		case 'year': return addYears(time, amount);
		default: return time;
		}
	},

	diff: function(max: number, min: number, unit: string) {
		switch (unit) {
		case 'millisecond': return differenceInMilliseconds(max, min);
		case 'second': return differenceInSeconds(max, min);
		case 'minute': return differenceInMinutes(max, min);
		case 'hour': return differenceInHours(max, min);
		case 'day': return differenceInDays(max, min);
		case 'week': return differenceInWeeks(max, min);
		case 'month': return differenceInMonths(max, min);
		case 'quarter': return differenceInQuarters(max, min);
		case 'year': return differenceInYears(max, min);
		default: return 0;
		}
	},

	startOf: function(time: number | Date, unit: string, weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6) {
		switch (unit) {
		case 'second': return startOfSecond(time);
		case 'minute': return startOfMinute(time);
		case 'hour': return startOfHour(time);
		case 'day': return startOfDay(time);
		case 'week': return startOfWeek(time);
		case 'isoWeek': return startOfWeek(time, {weekStartsOn: (+weekday) as 0 | 1 | 2 | 3 | 4 | 5 | 6});
		case 'month': return startOfMonth(time);
		case 'quarter': return startOfQuarter(time);
		case 'year': return startOfYear(time);
		default: return time;
		}
	},

	endOf: function(time: number | Date, unit: string) {
		switch (unit) {
		case 'second': return endOfSecond(time);
		case 'minute': return endOfMinute(time);
		case 'hour': return endOfHour(time);
		case 'day': return endOfDay(time);
		case 'week': return endOfWeek(time);
		case 'month': return endOfMonth(time);
		case 'quarter': return endOfQuarter(time);
		case 'year': return endOfYear(time);
		default: return time;
		}
	}
});