import React from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import * as L from 'leaflet';
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled';
import '@elfalem/leaflet-curve';
import 'leaflet-makimarkers';
import 'leaflet-easybutton';
import { useTheme, ThemeType } from '../../../contexts/theme/ThemeContext';
import { Buoy, buoyApi, BuoyStatus } from '../../../api/buoy.api';
import { MapAction, MapState, MapActionType, toTimeRange } from '../../views/buoy-tracker-map/map-state';
import { createTooltip, setMapCenter } from '../../reusable/maps/leaflet-helper';
import { useIsPortrait } from '../../utility/responsive';
import * as turf from '@turf/turf';
import { useSnackbar } from 'notistack';
import FishingZonesTopoJson from './fishing-zones.topo.json';
import BreedingGroundsBufferTopoJson from './breeding-grounds-buffer.topo.json';
import BreedingGroundsTopoJson from './breeding-grounds.topo.json';
import { FeatureGroup, LatLngBounds } from 'leaflet';
import './buttonToolbar.css';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useUserRole } from '../../utility/useUserRole';
import { PointOfInterestCreateDialog } from '../points-of-interest/PointOfInterestCreateDialog';
import { MeasureDistanceDialog } from './measure-distance-dialog/MeasureDistanceDialog';
import axios from 'axios';

const NAUTICAL_MILE_CABLE_RATIO = 0.1;
const MAX_ZOOM = 22;

const MapId = `buoy-tracker-map-id-${Math.random().toFixed(5)}`;
const GeoLocationFocus: [number, number] = [43.630943, 15.730602];
const Zoom = 8;
const ZoomSnap = 0.3;
const MapBoxAccessToken = 'pk.eyJ1IjoidG12aWt0b3IiLCJhIjoiY2toa2pncjNiMWh3bzJ4bzlsY3RwaW5ubyJ9.7e-8Ez2E5bdMv53KK9U47A';
(L as any).MakiMarkers.accessToken = MapBoxAccessToken;
const BuoyMarkers = {
	Online: (L as any).MakiMarkers.icon({ color: '#2E8B57', size: 'm' }),
	Standby: (L as any).MakiMarkers.icon({ color: '#DC143C', size: 'm' }),
	Offline: (L as any).MakiMarkers.icon({ color: '#696969', size: 'm' }),
	// HACK: should be removed, simulating VMS integration
	HackShipColor: (L as any).MakiMarkers.icon({ color: '#3464eb', size: 'm' }),
};

const MasterIcon = (L as any).MakiMarkers.icon({ color: '#2E8B57', size: 'm' });
const SlaveIcon = (L as any).MakiMarkers.icon({ color: '#696969', size: 'm' });

const statusToBuoyMarker = (buoy: Buoy | undefined) => {
	if (!buoy) {
		return BuoyMarkers.Offline;
	}
	const { status, deviceKey } = buoy;
	// HACK: should be removed, simulating VMS integration
	if (deviceKey === '8CF9572000017038') return BuoyMarkers.HackShipColor;
	if (status === BuoyStatus.Online) return BuoyMarkers.Online;
	return BuoyMarkers.Standby;
};

const OldBuoyMarker = (L as any).MakiMarkers.icon({ color: '#cc6699', size: 's' });
const PointOfInterestMarker = (L as any).MakiMarkers.icon({ icon: 'star-stroked', color: '#969208', size: 'm' });
const MapIdDark = 'dark-v9';
const MapIdLight = 'streets-v11';
const MapboxTileUrlTemplate = 'https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
const LightTileLayer = L.tileLayer(MapboxTileUrlTemplate, {
	maxZoom: MAX_ZOOM,
	tileSize: 512,
	zoomOffset: -1,
	id: MapIdLight,
	accessToken: MapBoxAccessToken
});
const DarkTileLayer = L.tileLayer(MapboxTileUrlTemplate, {
	maxZoom: MAX_ZOOM,
	tileSize: 512,
	zoomOffset: -1,
	id: MapIdDark,
	accessToken: MapBoxAccessToken
});

// L.Draw translations
const Lany = (L as any);
Lany.drawLocal.draw.handlers.polyline = {
	error: 'Greška! Linije se ne smiju presijecati.',
	tooltip: {
		cont: 'Pritisnite za nastavak mjerenja',
		end: 'Pritisnite zadnju točku za završetak mjerenja',
		start: 'Pritisnite za početak mjerenja',
	},
};
Lany.drawLocal.draw.handlers.polygon = {
	tooltip: {
		cont: 'Pritisnite za nastavak označavanja',
		end: 'Pritisnite zadnju točku za završetak',
		start: 'Označite prvu točku',
	},
};
Lany.drawLocal.draw.handlers.rectangle = {
	tooltip: {
		start: 'Pritisnite i povucite pravokutnik',
	},
};
Lany.drawLocal.draw.handlers.simpleshape = {
	tooltip: {
		end: 'Pustite za završetak',
	},
};
Lany.drawLocal.draw.toolbar.buttons = {
	circle: 'Kružnica',
	circlemarker: 'Kružni marker',
	marker: 'Marker',
	polygon: 'Označavanje poligonom',
	polyline: 'Mjerenje udaljenosti',
	rectangle: 'Označavanje pravokutnikom',
};
Lany.drawLocal.draw.toolbar.actions = {
	text: 'Prekini',
	title: 'Prekini',
};
Lany.drawLocal.draw.toolbar.finish = {
	text: 'Završi',
	title: 'Završi',
};
Lany.drawLocal.draw.toolbar.undo = {
	text: 'Izbriši zadnju točku',
	title: 'Izbriši zadnju točku',
};

const maxBounds: LatLngBounds = new LatLngBounds([41.96483, 13.08916], [46.56498, 19.45911]);

const useStyles = makeStyles(() => {
	return {
		map: {
			position: 'relative',
			width: '100%',
			height: '100%',
			zIndex: 1
		},
		defaultCursor: {
			cursor: 'default !important',
		},
	};
});

interface BuoyTrackerMapProps {
	mapState: MapState;
	mapDispatch: React.Dispatch<MapAction>;
	onFetchBuoysAndPois?: any;
}

export const BuoyTrackerMap = (props: BuoyTrackerMapProps) => {
	const { theme } = useTheme();
	const map = React.useRef<L.Map | undefined>();
	const historyRef = React.useRef<L.FeatureGroup | undefined>();
	const selectedRef = React.useRef<L.FeatureGroup | undefined>();
	const isPortrait = useIsPortrait();
	const mapState = React.useRef<MapState>(props.mapState);
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const deviceSetsLayer = React.useRef<L.FeatureGroup>(new FeatureGroup());
	const [fishingZonesLayer, setFishingZonesLayer] = React.useState<L.FeatureGroup>();
	const [deviceSets, setDeviceSets] = React.useState<{ master: any, slaves: [any, any] }[]>([]); 

	React.useEffect(() => {
		axios.get('/api/device-set').then(res => {
			setDeviceSets(res.data);
		});
	}, []);

	React.useEffect(() => {
		mapState.current = props.mapState;
	}, [props.mapState]);

	// change theme
	React.useEffect(() => {
		if (!map.current) return;
		map.current.removeLayer(LightTileLayer);
		map.current.removeLayer(DarkTileLayer);
		map.current.addLayer(theme.type === ThemeType.Light ? LightTileLayer : DarkTileLayer);
		LightTileLayer.bringToBack();
		DarkTileLayer.bringToBack();
	}, [theme.type]);

	// map and zones
	React.useEffect(() => {
		if (map.current) return;
		map.current = L.map(MapId, {
			maxZoom: MAX_ZOOM,
			center: GeoLocationFocus,
			zoom: Zoom,
			zoomSnap: ZoomSnap,
			attributionControl: false,
			layers: theme.type === ThemeType.Light ? [LightTileLayer] : [DarkTileLayer],
			zoomControl: false,
			drawControl: false,
		} as any);
		map.current.setZoom(map.current.getBoundsZoom(maxBounds));
		map.current.fitBounds(maxBounds);

		// fishing zones
		const fishingZonesLayer = (L as any).vectorGrid.slicer(FishingZonesTopoJson, {
			maxZoom: MAX_ZOOM,
			interactive: true,
			vectorTileLayerStyles: {
				fishing_zones: function (_properties: any, _zoom: any) {
					return {
						fill: true,
						stroke: true,
						color: '#3784fb',
						opacity: 1,
						weight: 1,
					};
				}
			},
			getFeatureId: (feature: any) => {
				return feature.properties.Podzona;
			}
		})
		.addTo(map.current);
		setFishingZonesLayer(fishingZonesLayer);

		let lastClickedId: number | undefined = undefined;
		fishingZonesLayer.on('click', (e: any) => {
			const id = e.layer.properties.Podzona;
			if (lastClickedId) fishingZonesLayer.resetFeatureStyle(lastClickedId);
			if (lastClickedId === id) {
				lastClickedId = undefined;
				props.mapDispatch({ type: MapActionType.SelectFishingZone, fishingZoneId: '/', fishingZone: {} });
				return;
			}
			lastClickedId = id;
			fishingZonesLayer.setFeatureStyle(id, {
				fill: true,
				stroke: true,
				color: 'orange',
				opacity: 1,
				weight: 1,
			});
			props.mapDispatch({ type: MapActionType.SelectFishingZone, fishingZoneId: id, fishingZone: e.layer.properties });
		});

		// Buttons for map control
		const toolbarButtonStyle = L.Browser.touch ? 'leafletButtonTouch' : 'leafletButton';
		const toolbarStyle = L.Browser.touch ? 'mapControlBarTouch' : 'mapControlBar';
		const additionalToolbarBtns = [];

		const refocusButton = Lany.easyButton('<span class="target">&target;</span>', () => {
			map.current?.setView(GeoLocationFocus, Zoom);
		}, 'Resetiraj kartu', toolbarButtonStyle);
		additionalToolbarBtns.push(refocusButton);

		const reloadBuoys = Lany.easyButton('<span class="target">&circlearrowright;</span>', () => {
			props.onFetchBuoysAndPois();
		}, 'Osvježi', toolbarButtonStyle);
		additionalToolbarBtns.push(reloadBuoys);

		Lany.easyBar(additionalToolbarBtns, { position: 'topleft', id: toolbarStyle, leafletClasses: true }).addTo(map.current);

		// Polygons
		const drawControl = new Lany.Control.Draw({
			position: 'topleft',
			draw: {
				circle: false,
				marker: false,
				circlemarker: false,
			},
		});
		map.current.addControl(drawControl);

		map.current.on(Lany.Draw.Event.CREATED, (e: any) => {
			const { layerType, layer } = e as any;
			if (layerType === 'polygon' || layerType === 'rectangle') {
				const points = Object.values(mapState.current.buoys)
					.filter(filterInvalidGeolocation)
					.map((buoy: Buoy) => turf.point([buoy.longitude!, buoy.latitude!], { buoy }));
				const selectedCollection = turf.within(
					turf.featureCollection(points),
					layer.toGeoJSON() as any as turf.MultiPolygon,
				);
				const selectedBuoyIds: string[] = selectedCollection.features.map(feature => feature.properties?.buoy.id);
				props.mapDispatch({ type: MapActionType.SelectBuoys, buoyIds: selectedBuoyIds });
			}
			if (layerType === 'polyline') {
				const line = turf.lineString(
					((layer as L.Polyline).getLatLngs() as L.LatLng[])
						.map(({ lat, lng }) => [lng, lat]),
				);
				const distanceM: number = turf.length(line, { units: 'meters' });
				const distanceMile: number = turf.length(line, { units: 'miles' });
				const distanceCable: number = turf.length(line, { units: 'nauticalmiles' }) * NAUTICAL_MILE_CABLE_RATIO;
				const message = `Izmjerena udaljenost: ${distanceM.toFixed(0)} m / ${distanceMile.toFixed(3).replace('.', ',')} mi / ${distanceCable.toFixed(3).replace('.', ',')} kabela`;
				enqueueSnackbar(message, { variant: 'success' });
			}
		});


		return () => {
			map.current?.remove(); 
		};
	}, []);

	React.useEffect(() => {
		if (!map.current || !fishingZonesLayer) return;
		if (props.mapState.fishingZonesVisible) {
			!map.current.hasLayer(fishingZonesLayer) && map.current.addLayer(fishingZonesLayer);
		} else {
			map.current.hasLayer(fishingZonesLayer) && map.current.removeLayer(fishingZonesLayer);
		}
	}, [props.mapState.fishingZonesVisible]);

	React.useEffect(() => {
		map.current?.removeLayer(deviceSetsLayer.current);
		deviceSetsLayer.current = new FeatureGroup();
		if (!map.current || !deviceSets) return;
		Object.values(deviceSets)
			.forEach(({ master, slaves: [slave1, slave2] }) => {
				const [
					masterMarker,
					slave1Marker,
					slave2Marker
				] = [
					L.marker([master.attributes.latitude!, master.attributes.longitude!], { icon: MasterIcon }),
					L.marker([slave1.attributes.latitude!, slave1.attributes.longitude!], { icon: SlaveIcon }),
					L.marker([slave2.attributes.latitude!, slave2.attributes.longitude!], { icon: SlaveIcon })
				];
				// const buoyMarker = L.marker([buoy.latitude!, buoy.longitude!], { icon: statusToBuoyMarker(buoy) });
				masterMarker.addTo(deviceSetsLayer.current);
				slave1Marker.addTo(deviceSetsLayer.current);
				slave2Marker.addTo(deviceSetsLayer.current);

				const style: React.CSSProperties = {
					display: 'flex',
					justifyContent: 'space-between'
				};

				masterMarker.bindTooltip(
					createTooltip(<div style={{ width: '280px' }}>
						<div style={style}><span>Ime:</span><span>{master.name}</span></div>
						<div style={style}><span>Šifra uređaja:</span><span>{master.deviceKey}</span></div>
						<div style={style}><span>Vrijeme zadnje poruke:</span><span>{master.lastMessageReceivedAt}</span></div>
						<div style={style}><span>Geografska širina:</span><span>{master.attributes.latitude!.toFixed(5)}</span></div>
						<div style={style}><span>Geografska dužina:</span><span>{master.attributes.longitude!.toFixed(5)}</span></div>
					</div>),
					{ direction: 'left', interactive: true }
				);

				slave1Marker.bindTooltip(
					createTooltip(<div style={{ width: '280px' }}>
						<div style={style}><span>Ime:</span><span>{slave1.name}</span></div>
						<div style={style}><span>Šifra uređaja:</span><span>{slave1.deviceKey}</span></div>
						<div style={style}><span>Vrijeme zadnje poruke:</span><span>{slave1.lastMessageReceivedAt}</span></div>
						<div style={style}><span>Geografska širina:</span><span>{slave1.attributes.latitude!.toFixed(5)}</span></div>
						<div style={style}><span>Geografska dužina:</span><span>{slave1.attributes.longitude!.toFixed(5)}</span></div>
					</div>),
					{ direction: 'left', interactive: true }
				);

				slave2Marker.bindTooltip(
					createTooltip(<div style={{ width: '280px' }}>
						<div style={style}><span>Ime:</span><span>{slave2.name}</span></div>
						<div style={style}><span>Šifra uređaja:</span><span>{slave2.deviceKey}</span></div>
						<div style={style}><span>Vrijeme zadnje poruke:</span><span>{slave2.lastMessageReceivedAt}</span></div>
						<div style={style}><span>Geografska širina:</span><span>{slave2.attributes.latitude!.toFixed(5)}</span></div>
						<div style={style}><span>Geografska dužina:</span><span>{slave2.attributes.longitude!.toFixed(5)}</span></div>
					</div>),
					{ direction: 'left', interactive: true }
				);
			});
		deviceSetsLayer.current.addTo(map.current);
		if (deviceSetsLayer.current.getBounds().isValid()) {
			map.current.fitBounds(deviceSetsLayer.current.getBounds().pad(2));
		}
	}, [deviceSets]);

	return (<div
			id={MapId}
			className={clsx([
				classes.map
			])}
		/>);
};

const filterInvalidGeolocation = (object: unknown): boolean => {
	if (!object) return false;
	const latitude = (object as any).latitude as number;
	const longitude = (object as any).longitude as number;
	if (typeof latitude !== 'number' || typeof longitude !== 'number') return false;
	if (!Number.isFinite(latitude) || !Number.isFinite(longitude)) return false;
	if (latitude < -180 || latitude > 180) return false;
	if (longitude < -90 || longitude > 90) return false;
	return true;
};