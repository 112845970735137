import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Table, { Column, FetchPageAsPromise, TableToolbarActions, TableToolbarActionsProps } from '../../reusable/Table';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { subMonths, startOfDay, endOfDay, isValid } from 'date-fns';
import { buoyApi, BuoyGeolocationPoint } from '../../../api/buoy.api';
import { hr } from 'date-fns/locale';


interface TableToolbarProps extends TableToolbarActionsProps {
	from: Date;
	setFrom: React.Dispatch<React.SetStateAction<Date>>;
	to: Date;
	setTo: React.Dispatch<React.SetStateAction<Date>>;
}

const TableToolbar = (props: TableToolbarProps) => {
	const { from, setFrom, to, setTo } = props;

	return <>
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
			<KeyboardDatePicker
				disableFuture
				label='Od'
				disableToolbar
				variant='inline'
				format='dd/MM/yyyy'
				margin='normal'
				value={from}
				onChange={(date: Date | null) => date && isValid(date) && setFrom(startOfDay(date))}
				style={{marginRight: '10px'}}
			/>
		</MuiPickersUtilsProvider>
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hr}>
			<KeyboardDatePicker
				disableFuture
				label='Do'
				disableToolbar
				variant='inline'
				format='dd/MM/yyyy'
				margin='normal'
				value={to}
				onChange={(date: Date | null) => date && isValid(date) && setTo(endOfDay(date))}
				style={{marginRight: '10px'}}
			/>
		</MuiPickersUtilsProvider>
		<TableToolbarActions
			{...props}
			labels={{ refresh: 'Osvježi' }}
		/>
	</>;
};

const useStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden'
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto'
	}
});

interface Props {
    buoyId: string;
}

export const BuoyGeolocationHistoryTable = (props: Props) => {
	const { buoyId } = props;
	const [from, setFrom] = useState<Date>(startOfDay(subMonths(new Date(), 1)));
	const [to, setTo] = useState<Date>(endOfDay(new Date()));
	const classes = useStyles();

	const getPage: FetchPageAsPromise<BuoyGeolocationPoint> = useCallback((pagingOpts) => {
		return buoyApi.getGeoLocationHistoryPaginated(buoyId, from, to, pagingOpts);
	}, [buoyId, from, to]);

	return (
		<div className={classes.container}>
			<div className={classes.tableContainer}>
				<Table fetchTablePageFn={getPage} options={{ title: 'Povijesni podaci', defaultPageSize: 5, pageSizeOptions: [5, 10, 25, 50] }}
					toolbarActions={
						<TableToolbar from={from} setFrom={setFrom} to={to} setTo={setTo} />
					}
				>
					<Column label='Geografska širina'>
					{
						(row: BuoyGeolocationPoint) => <>{row.latitude.toString()}</>
					}
					</Column>
					<Column label='Geografska dužina'>
					{
						(row: BuoyGeolocationPoint) => <>{row.longitude.toString()}</>
					}
					</Column>
					<Column label='Broj satelita'>
					{
						(row: BuoyGeolocationPoint) => <>{row.noSattelites?.toString() ?? '/'}</>
					}
					</Column>
					<Column label='HDOP'>
					{
						(row: BuoyGeolocationPoint) => <>{row.acquiredHdop?.toString() ?? '/'}</>
					}
					</Column>
					<Column label='HEPE'>
					{
						(row: BuoyGeolocationPoint) => <>{row.acquiredHepe?.toString() ?? '/'}</>
					}
					</Column>
					<Column label='Trajanje geolociranja (sec)'>
					{
						(row: BuoyGeolocationPoint) => <>{row.timeAcquiringSec?.toString() ?? '/'}</>
					}
					</Column>
					<Column label='Vrijeme poruke'>
					{
						(row: BuoyGeolocationPoint) => <>{new Date(row.t).toLocaleString('hr')}</>
					}
					</Column>
				</Table>
			</div>
		</div>
	);
};
