
import React, { useState } from 'react';
import { Dialog, DialogTitle, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { Buoy, buoyApi } from '../../../../api/buoy.api';
import { BuoyChangePassword } from './buoy-change-password/BuoyChangePassword';
import { BuoyGeneralSettings } from './buoy-general-settings/BuoyGeneralSettings';
import { BuoyGpsSettings } from './buoy-gps-settings/BuoyGpsSettings';

interface Props {
	buoys: Buoy[];
	open: boolean;
	onClose?: () => void;
}

export const useBuoyConfigureDialogStyles = makeStyles(_theme => {
	return {
		tabPanel: {}
	};
});

export const BuoyConfigureDialog = (props: Props) => {
	const { buoys, open, onClose } = props;
	const classes = useBuoyConfigureDialogStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [tab, setTab] = useState('0');

	return (
		<Dialog
			onClose={() => {
				onClose && onClose();
			}}
			open={open}
		>
			<div style={{
				padding: '0px 16px 0px 16px',
			}}>
				<DialogTitle style={{
					padding: '16px 16px 0px 0px',
				}}>
					<span style={{ fontWeight: 800 }}>Konfiguriraj {buoys.length > 1 ? 'bove' : 'bovu'}</span>
				</DialogTitle>
				<TabContext value={tab}>
					<TabList
						variant='scrollable'
						indicatorColor='primary'
						textColor='primary'
						onChange={(_, newTab: string) => setTab(newTab)}
						scrollButtons='auto'
					>
						<Tab label='Općenite postavke' value='0' />
						<Tab label='GPS postavke' value='1' />
						<Tab label='Promijeni šifru' value='2' />
					</TabList>
					<TabPanel className={classes.tabPanel} value='0'>
						<BuoyGeneralSettings
							buoyDeviceAttributes={buoys.length === 1 ? buoys[0].deviceAttributes : undefined}
							onSend={async msg => {
								try {
									await buoyApi.sendBuoyConfigurationMsg(buoys.map(b => b.id), msg);
									enqueueSnackbar(`Zahtjev za promjenu konfiguracije ${buoys.length > 1 ? 'bova' : `bove`} poslan.`, { variant: 'success' });
								} catch (error) {
									enqueueSnackbar(`Zahtjev za promjenu konfiguracije ${buoys.length > 1 ? 'bova' : `bove`} je neuspješan.`, { variant: 'error' });
								}
								onClose?.();
							}}
							onCancel={() => onClose?.()}
						/>
					</TabPanel>
					<TabPanel className={classes.tabPanel} value='1'>
						<BuoyGpsSettings
							buoyDeviceAttributes={buoys.length === 1 ? buoys[0].deviceAttributes : {}}
							onSend={async msg => {
								try {
									await buoyApi.sendBuoyGpsConfigurationMsg(buoys.map(b => b.id), msg);
									enqueueSnackbar(`Zahtjev za promjenu GPS postavki ${buoys.length > 1 ? 'bova' : `bove`} poslan.`, { variant: 'success' });
								} catch (error) {
									enqueueSnackbar(`Zahtjev za promjenu GPS postavki ${buoys.length > 1 ? 'bova' : `bove`} je neuspješan.`, { variant: 'error' });
								}
								onClose?.();
							}}
							onCancel={() => onClose?.()}
						/>
					</TabPanel>
					<TabPanel className={classes.tabPanel} value='2'>
						<BuoyChangePassword
							onSend={async password => {
								try {
									await buoyApi.sendChangePasswordMsg(buoys.map(b => b.id), password);
									enqueueSnackbar(`Zahtjev za promjenu šifre ${buoys.length > 1 ? 'bova' : `bove`} poslan.`, { variant: 'success' });
								} catch (error) {
									enqueueSnackbar(`Zahtjev za promjenu šifre ${buoys.length > 1 ? 'bova' : `bove`} je neuspješan.`, { variant: 'error' });
								}
								onClose?.();
							}}
							onCancel={() => onClose?.()}
						/>
					</TabPanel>
				</TabContext>
			</div>
		</Dialog>
	);
};
