import React from 'react';
import { Fab, CircularProgress, Button, FormControl, InputLabel, MenuItem, Select, Tooltip, IconButton } from '@material-ui/core';
import { Drawer, DrawerAnchor } from './reusable/Drawer';
import { Clear, DoubleArrow } from '@material-ui/icons';
import { useCurrentTheme, ThemeType } from '../contexts/theme/ThemeContext';
import { TimeSeriesChart } from './reusable/charts/TimeSeriesChart';
import { makeStyles } from '@material-ui/styles';
import { LabeledText } from './reusable/LabeledText';
import { useSnackbar } from 'notistack';
import { GenericRequestErrorMsg } from './utility/general-messages';
import { getBuoyShowRoute } from './views/buoys/BuoyRouter';
import { useHistory } from 'react-router-dom';
import { useIsPortrait } from './utility/responsive';
import { Buoy, buoyApi } from '../api/buoy.api';
import { MapAction, MapActionType, MapState, toTimeRange } from './views/buoy-tracker-map/map-state';
import { MultipleBuoysDetails } from './MultipleBuoysDetails';
import { BuoyDownlinkButtons } from './views/buoys/BuoyDownlinkButtons';
import { AttrDef, attrDefSetApi, Attribute } from '../api/attr-def-set.api';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import hrLocale from 'date-fns/locale/hr';

const useBuoyDetailsStyles = makeStyles({
	container: {
		position: 'relative',
		paddingTop: '20px',
		maxHeight: '100%',
		overflowY: 'auto',
	},
	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%'
	},
	info: {
		paddingLeft: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			marginRight: 40
		}
	},
	chartContainer: {
		height: '400px',
		marginTop: '50px'
	},
	lastTHoursSelect: {
		width: '200px'
	},
	configButtons: {
		float: 'right'
	},
});

const BuoyDetails = ({ id, mapState, mapDispatch }: { id?: string, mapState: MapState, mapDispatch: React.Dispatch<MapAction> }) => {
	const { type, colors } = useCurrentTheme();
	const classes = useBuoyDetailsStyles();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [attrDefs, setAttrDefs] = React.useState<AttrDef[]>([]);
	const [attrs, setAttrs] = React.useState<Record<string, Attribute>>({});

	const [buoy, setBuoy] = React.useState<Buoy | undefined>(undefined);
	const [batteryHist, setBatteryHist] = React.useState<{ t: string; y: string }[]>();
	const [batteryVoltageHist, setBatteryVoltageHist] = React.useState<{ t: string; y: string }[]>();
	const [temperatureHist, setTemperatureHist] = React.useState<{ t: string; y: string }[]>();

	const [historyPeriodOption, setHistoryPeriodOption] = React.useState<number | 'TIME_RANGE'>();
	const handleHistoryPeriodOptChange = React.useCallback((opt: number | 'TIME_RANGE') => {
		if (typeof opt === 'number') {
			mapDispatch({ type: MapActionType.SetHistoryPeriodOption, historyPeriodOption: { type: 'LAST_N_HOURS', hours: opt } });
			setFrom(undefined);
			setTo(undefined);
		} else {
			setHistoryPeriodOption('TIME_RANGE');
		}
	}, []);
	React.useEffect(() => {
		setHistoryPeriodOption(mapState.historyPeriodOption.type === 'LAST_N_HOURS' ? mapState.historyPeriodOption.hours : 'TIME_RANGE');
	}, [mapState.historyPeriodOption]);

	const [from, setFrom] = React.useState<Date | undefined>();
	const [to, setTo] = React.useState<Date | undefined>();
	React.useEffect(() => {
		if (from && isValid(from) && to && isValid(to)) {
			mapDispatch({ type: MapActionType.SetHistoryPeriodOption, historyPeriodOption: { type: 'TIME_RANGE', from, to } });
		}
	}, [from, to]);

	React.useEffect(() => {
		(async () => {
			if (!id) return;
			setBuoy(undefined);
			try {
				const buoy = await buoyApi.get(id);
				setBuoy(buoy);
				const [definitions, attributes] = await Promise.all([
					buoy.attributeDefinitionSetId ? attrDefSetApi.getDefinitions(buoy.attributeDefinitionSetId) : Promise.resolve(undefined),
					buoyApi.getAttributes(id)
				]);
				if (definitions) setAttrDefs(definitions);
				setAttrs((attributes || []).reduce((acc, cur) => {
					acc[cur.definitionId] = cur;
					return acc;
				}, {} as Record<string, Attribute>));
			} catch (error) {
				enqueueSnackbar(GenericRequestErrorMsg('bova'), { variant: 'error' });
			}
		})();
	}, [id]);

	const fetchTelemetry = React.useCallback(async (id: string, from?: Date, to?: Date) => {
		try {
			const [
				batteryHist,
				batteryVoltageHist,
				temperatureHist
			] = await Promise.all([
				buoyApi.getBuoyTelemetry(id, 'battery', from, to),
				buoyApi.getBuoyTelemetry(id, 'battery_voltage', from, to),
				buoyApi.getBuoyTelemetry(id, 'temperature', from, to)	
			]);
			setBatteryHist(batteryHist);
			setBatteryVoltageHist(batteryVoltageHist);
			setTemperatureHist(temperatureHist);
		} catch (error) {
			enqueueSnackbar(GenericRequestErrorMsg('telemetrije'), { variant: 'error' });
		}
	}, []);
	React.useEffect(() => {
		if (!id) return;
		const { from, to } = toTimeRange(mapState.historyPeriodOption, mapState.currentMapTime);
		fetchTelemetry(id, from, to);
	}, [id, mapState.historyPeriodOption, mapState.currentMapTime]);

	return (
		<div className={classes.container}>
			{
				!buoy ? (
					<div className={classes.loading}>
						<CircularProgress size={80} thickness={2} />
					</div>
				) : <>
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '0 20px 10px 20px'
						}}>
							<Button
								onClick={_ => history.push(getBuoyShowRoute(buoy.id))}
								variant="contained"
								color="primary"
								endIcon={<DoubleArrow />}>
								Na detaljniji prikaz
      				</Button>
							{
								buoy && <div className={classes.configButtons}>
									<BuoyDownlinkButtons buoys={[buoy]} />
								</div>
							}
						</div>
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '0 20px 10px 20px'
						}}>
							<FormControl>
								<InputLabel id="time-range-select">Vremenski interval</InputLabel>
								<Select
									label={'Vremenski interval'}
									className={classes.lastTHoursSelect}
									labelId="time-range-select"
									value={historyPeriodOption}
									onChange={e => {
										handleHistoryPeriodOptChange(e.target.value as number | 'TIME_RANGE');
									}}
								>
									<MenuItem value={6}>6 sati</MenuItem>
									<MenuItem value={12}>12 sati</MenuItem>
									<MenuItem value={24}>24 sata / 1 dan</MenuItem>
									<MenuItem value={48}>48 sati / 2 dana</MenuItem>
									<MenuItem value={72}>72 sata / 3 dana</MenuItem>
									<MenuItem value={96}>96 sata / 4 dana</MenuItem>
									<MenuItem value={120}>120 sata / 5 dana</MenuItem>
									<MenuItem value={'TIME_RANGE'}>Odabir intervala</MenuItem>
								</Select>
							</FormControl>
						</div>
						{ historyPeriodOption === 'TIME_RANGE' ? <div style={{
							display: 'flex',
							marginLeft: '20px'
						}}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
								<KeyboardDatePicker
									disableFuture
									label='Od'
									disableToolbar
									variant='inline'
									format='dd/MM/yyyy'
									margin='normal'
									value={from || null}
									onChange={(date: Date | null) => setFrom(date ? startOfDay(date) : undefined)}
									style={{ marginRight: '10px' }}
								/>
							</MuiPickersUtilsProvider>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
								<KeyboardDatePicker
									disableFuture
									label='Do'
									disableToolbar
									variant='inline'
									format='dd/MM/yyyy'
									margin='normal'
									value={to || null}
									onChange={(date: Date | null) => setTo(date ? endOfDay(date) : undefined)}
								/>
							</MuiPickersUtilsProvider>
						</div> : <></>}
						<div className={classes.info}>
							<LabeledText label='Ime' value={buoy.name || '/'} />
							<LabeledText label='Šifra uređaja' value={buoy.deviceKey} />
							<LabeledText label='Vrijeme zadnje poruke' value={buoy?.lastMessageAt?.toLocaleString('hr') || '/'} />
							<LabeledText label='Vrijeme zadnje geolokacije' value={buoy?.lastGeoLocAt?.toLocaleString('hr') || '/'} />
							<LabeledText label='Baterija' value={buoy.batteryPercentage ? `${buoy?.batteryPercentage}%` : '/'} />
							<LabeledText label='Temperatura' value={buoy.temperature ? `${buoy?.temperature}°C` : '/'} />
							<LabeledText label='Status' value={buoy.status || '/'} />
						</div>
						<div className={classes.info}>
							{
								attrDefs && attrs ?
									attrDefs.map(def => <LabeledText label={def.name} value={attrs[def.id]?.value} />) : null
							}
						</div>
						<div className={classes.chartContainer} style={{
							// margin: isPortrait ? undefined : '15px'
							// margin: '15px'
						}}>
							{
								!temperatureHist ? (
									<div className={classes.loading}>
										<CircularProgress size={80} thickness={2} />
									</div>
								) : <TimeSeriesChart
										legendLabel='Temperatura °C'
										timeseries={temperatureHist}
										lineColor={colors.main}
										textColor={type === ThemeType.Dark ? 'white' : 'black'}
										gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
										yAxesSettings={{ min: 0, max: 60 }} />
							}
						</div>
						<div className={classes.chartContainer} style={{
							// margin: isPortrait ? undefined : '15px'
							// margin: '15px'
						}}>
							{
								!batteryHist ? (
									<div className={classes.loading}>
										<CircularProgress size={80} thickness={2} />
									</div>
								) : <TimeSeriesChart
										legendLabel='Baterija %'
										timeseries={batteryHist}
										lineColor={colors.main}
										textColor={type === ThemeType.Dark ? 'white' : 'black'}
										gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
										yAxesSettings={{ min: 0, max: 100 }} />
							}
						</div>
						<div className={classes.chartContainer} style={{
							// margin: isPortrait ? undefined : '15px'
							// margin: '15px'
						}}>
							{
								!batteryVoltageHist ? (
									<div className={classes.loading}>
										<CircularProgress size={80} thickness={2} />
									</div>
								) : <TimeSeriesChart
										legendLabel='Napon baterije (V)'
										timeseries={batteryVoltageHist}
										lineColor={colors.main}
										textColor={type === ThemeType.Dark ? 'white' : 'black'}
										gridLinesColor={type === ThemeType.Dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
										yAxesSettings={{ min: 0, max: 5 }} />
							}
						</div>
					</>
			}
		</div>
	);
};

interface MapDrawerProps {
	ids: string[];
	width?: string;
	height?: string;
	container?: HTMLElement | null;
	onClose?: () => void | any;
	overlay?: boolean;
	open?: boolean;
	mapState: MapState;
	mapDispatch: React.Dispatch<MapAction>;
}

export const MapDrawer = (props: MapDrawerProps) => {
	const { ids } = props;
	// responsive stuff
	const isPortrait = useIsPortrait();
	const onClose = props.onClose;
	const closeHandler = React.useCallback(() => {
		props.mapDispatch({ type: MapActionType.SetHistoryPeriodOption, historyPeriodOption: { type: 'LAST_N_HOURS', hours: 24 } });
		onClose?.();
	}, [onClose]);

	return (
		<Drawer
			onClose={() => closeHandler()}
			open={!!ids.length}
			anchor={isPortrait ? DrawerAnchor.Bottom : DrawerAnchor.Right}
			container={props.container}
			width={isPortrait ? undefined : (props.width || '50%')}
			height={isPortrait ? (props.height || '45%') : undefined}
			zIndex={10}
		>
			{(open, _setOpen) => (
				<div style={{
					position: 'relative',
					height: '100%',
					width: '100%'
				}}>
					{
						open ? <Fab color="primary" aria-label="add" style={{
							position: 'absolute',
							// landscape pos
							left: isPortrait ? undefined : -50,
							// portrait pos
							right: isPortrait ? 10 : undefined,
							top: isPortrait ? -50 : 10
						}}
							onClick={_ => closeHandler()}
						>
							<Clear />
						</Fab> : <></>
					}

					{
						ids.length === 1
							? <BuoyDetails id={props.ids.length ? props.ids[0] : undefined} mapState={props.mapState} mapDispatch={props.mapDispatch} />
							: <MultipleBuoysDetails ids={ids} mapState={props.mapState} mapDispatch={props.mapDispatch} closeFn={() => closeHandler()} />
					}
				</div>
			)}
		</Drawer>
	);
};
