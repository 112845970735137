import React, { useState } from 'react';
import { Card, TextField, Button, ThemeOptions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { authService } from '../api/auth.api';
import { useTheme, ThemeType } from '../contexts/theme/ThemeContext';
import { useIsPortrait } from './utility/responsive';
import { useSnackbar } from 'notistack';
import { MinistarstvoPoljoprivredeLogo, DarkThemeMinistarstvoPoljoprivredeLogo, BackgroundImg, DarkThemeEuLogos, DarkThemeOiVSmartinoLogo, EuLogo, OiVSmartinoLogo, EuLogoDisclaimerText } from '../../static';

const useLoginStyles = makeStyles((_theme: ThemeOptions) => {
	const { theme: { type } } = useTheme();
	const background = type === ThemeType.Light ? `url(${BackgroundImg}) no-repeat center center fixed` :
		`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${BackgroundImg}) no-repeat center center fixed`;
	return {
		container: {
			background,
			backgroundSize: 'cover',
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		cardContainer: {
			width: '550px'
		},
		cardContent: {
			width: '80%',
			margin: '40px auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		loginForm: {
			// width: '350px',
			width: '100%',
			maxWidth: '350px',
			display: 'flex',
			flexDirection: 'column'
		},
		formInput: {
			marginTop: '10px'
		},
		button: {
			marginTop: '10px',
			backgroundColor: 'rgb(192, 111, 54)'
		},
		appTitle: {
			fontWeight: 200,
			fontSize: 24,
			textAlign: 'center'
		},
		notice: {
			textAlign: 'center',
			marginBottom: '15px'
		}
	};
});

export const Login = () => {
	const classes = useLoginStyles();
	const { theme: { type } } = useTheme();
	const history = useHistory();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const isPortrait = useIsPortrait();

	const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (email === 'demo@telemetris.hr' && password === 'demotest') {
			enqueueSnackbar('Uspješna prijava', { variant: 'success' });
			history.push('/');
		} else {
			enqueueSnackbar('Greška prilikom prijave, neispravan email i/ili lozinka', { variant: 'error' });
		}
	}, [email, enqueueSnackbar, history, password]);

	return (
		<div className={classes.container}>
			<Card elevation={3} className={classes.cardContainer} style={{
				opacity: type === ThemeType.Light ? 0.85 : 0.95,
				width: isPortrait ? '90%' : undefined
			}}>
				<div className={classes.cardContent}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
						<div className={classes.appTitle}>
							NADZOR RONILAČKIH BOVA
						</div>
					</div>
					<form className={classes.loginForm}
						onSubmit={handleSubmit}
					>
						<TextField
							className={classes.formInput}
							margin='normal'
							label="Email"
							type="email"
							onChange={e => setEmail(e.target.value)}
						/>
						<TextField
							className={classes.formInput}
							margin='normal'
							label="Password"
							type="password"
							onChange={e => setPassword(e.target.value)}
						/>
						<Button type='submit' variant='contained' color='primary' size='medium'
							className={classes.button}>
							Login
						</Button>
					</form>
					<a href='https://oiv.hr/hr/usluge-i-platforme/oiv-smartino-iot/'>
						<img src={type === ThemeType.Light ? OiVSmartinoLogo : DarkThemeOiVSmartinoLogo} width={'100%'} alt='logo' />
					</a>
					<div style={{ display: 'flex', justifyContent: 'center', fontSize: '14px', fontWeight: 200 }}>
						Telemetris d.o.o.
					</div>
				</div>
			</Card>
		</div>
	);
};
