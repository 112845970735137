import React from 'react';
import { Typography } from '@material-ui/core';

export const LabeledText = ({ label, value }: { label: string, value: string }) => {
	return (
		<div>
			<Typography variant='caption' color="textSecondary">
				{label}
			</Typography>
			<Typography variant="subtitle1">
				{value}
			</Typography>
		</div>
	);
};