export enum FilterOperator {
	Eq = 'EQ',
	Neq = 'NEQ',
	Gt = 'GT',
	Gte = 'GTE',
	Lt = 'LT',
	Lte = 'LTE',
	Like = 'LIKE'
}

export enum FilterType {
	Literal = '_LITERAL',
	Exists = '_EXISTS',
	NotExists = '_NOT_EXISTS',
	Any = '_ANY',
	And = '_AND',
	Or = '_OR'
}

export type Filter =
	| { _type: '_LITERAL', name: string; operator: FilterOperator; value: any }
	| { _type: '_EXISTS', name: string }
	| { _type: '_NOT_EXISTS', name: string }
	| { _type: '_ANY', name: string }
	| { _type: '_AND', operands: Filter[] }
	| { _type: '_OR', operands: Filter[] }

// generic types are erased at runtime so we must pass 'filterType' arg to know which value for _type field to use
export const createFilterBuilder = <VALUE_TYPE, FILTER_TYPE>(name: string) => {
	return {
		eq: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Eq, value }) as any as FILTER_TYPE,
		neq: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Neq, value }) as any as FILTER_TYPE,
		lt: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Lt, value }) as any as FILTER_TYPE,
		lte: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Lte, value }) as any as FILTER_TYPE,
		gt: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Gt, value }) as any as FILTER_TYPE,
		gte: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Gte, value }) as any as FILTER_TYPE,
		like: (value: VALUE_TYPE) => ({ _type: FilterType.Literal, name, operator: FilterOperator.Like, value }) as any as FILTER_TYPE,
		exists: () => ({ _type: FilterType.Exists, name }) as any as FILTER_TYPE,
		notExists: () => ({ _type: FilterType.NotExists, name }) as any as FILTER_TYPE,
		any: () => ({ _type: FilterType.Any, name }) as any as FILTER_TYPE,
	};
};

export const And = <T extends Filter>(...operands: (T | undefined)[]) => ({ _type: '_AND', operands: operands.filter(operand => !!operand) } as Filter as T);
export const Or = <T extends Filter>(...operands: (T | undefined)[]) => ({ _type: '_OR', operands: operands.filter(operand => !!operand) } as Filter as T);

export const toQueryParams = (filter: Filter, nameMap?: { [key: string]: string | undefined }): string => {
	// recursively build where expressions string
	const aux = (filter: Filter): string => {
		switch (filter._type) {
			case '_AND': return filter.operands.map(operand => aux(operand)).join('&');
			case '_OR': return `(${filter.operands.map(operand => aux(operand)).join('|')})`;
			case '_LITERAL': {
				const { name, operator, value } = filter;
				if (nameMap && !nameMap[name]) throw new Error(`'nameMap' is defined yet for key = '${name}' value doesn't exist`);
				const mappedName = nameMap ? nameMap[name] : name;
				const stringValue = value instanceof Date ? value.toISOString() : String(value);
				switch (operator) {
					case FilterOperator.Eq: return `${mappedName}=eq:${stringValue}`;
					case FilterOperator.Neq: return `${mappedName}=neq:${stringValue}`;
					case FilterOperator.Gt: return `${mappedName}=gt:${stringValue}`;
					case FilterOperator.Gte: return `${mappedName}=gte:${stringValue}`;
					case FilterOperator.Lt: return `${mappedName}=lt:${stringValue}`;
					case FilterOperator.Lte: return `${mappedName}=lte:${stringValue}`;
					case FilterOperator.Like: return `${mappedName}=like:${stringValue}`;
					default: throw new Error(`Impossible case - all filter opperators options are covered.`);
				}
			}
			case '_EXISTS': {
				if (nameMap && !nameMap[filter.name]) throw new Error(`'nameMap' is defined yet for key = '${filter.name}' value doesn't exist`);
				const mappedName = nameMap ? nameMap[filter.name] : filter.name;
				return `${mappedName}=ex:true`;
			}
			case '_NOT_EXISTS': {
				if (nameMap && !nameMap[filter.name]) throw new Error(`'nameMap' is defined yet for key = '${filter.name}' value doesn't exist`);
				const mappedName = nameMap ? nameMap[filter.name] : filter.name;
				return `${mappedName}=ex:false`;
			}
			default: return '';
		}
	};

	return aux(filter);
};

export interface Paging {
	page: number;
	size: number;
}

export interface Page<T> {
	data: T[];
	total: number;
}

export interface Sorting {
	[key: string]: 'ASC' | 'DESC';
}

export const renameObjectKeys = <T>(object: { [key: string]: T }, renameFn: (key: string) => string) => {
	const newOb = {} as any;
	for (const key of Object.keys(object)) {
		newOb[renameFn(key)] = object[key];
	}

	return newOb;
};
