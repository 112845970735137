import React, { useState, useCallback } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Table, { Column, RowSelectionColumn, TableToolbarActions, TableToolbarActionsProps } from '../../../reusable/Table';
import { Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Id, buoyApi, PairedBuoyDetails } from '../../../../api/buoy.api';
import { getBuoyShowRoute } from '../BuoyRouter';
import { AsyncSearchBuoy } from '../AsyncSearchBuoy';
import { BuoyPairAlarmDialog } from './BuoyPairAlarmDialog';


const TableToolbar = (props: TableToolbarActionsProps & { buoyId: string }) => {
	const { buoyId } = props;
	const [selectedBuoyId, setSelectedBuoyId] = useState<string | null>(null);
	const { enqueueSnackbar } = useSnackbar();

	const onClose = useCallback(() => {
		setSelectedBuoyId(null);
		props.refresh?.();
	}, [props]);

	return <>
		{
			selectedBuoyId &&
				<BuoyPairAlarmDialog
					fstBuoyId={buoyId}
					sndBuoyId={selectedBuoyId}
					open={true}
					onClose={onClose}
				/>
		}
		<AsyncSearchBuoy
			filter={Id.neq(buoyId)}
			style={{ width: '350px' }}
			placeholder='Upari bovu'
			onChange={newBuoyId => setSelectedBuoyId(newBuoyId)}
		/>
		<TableToolbarActions
			{...props}
			labels={{ delete: 'Raspari', refresh: 'Osvježi' }}
			deleteSelected={async (selectedRows: Set<string>, deselectRows, refresh) => {
				await buoyApi.unpairBuoys(Array.from(selectedRows));
				enqueueSnackbar('Bove uspješno rasparene', { variant: 'success' });
				deselectRows();
				refresh();
			}}
		/>
	</>;
};

const useStyles = makeStyles({
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		overflow: 'hidden',
	},
	tableContainer: {
		height: '100%',
		padding: '20px',
		paddingBottom: '20px',
		overflow: 'auto',
	},
});

interface Props {
	buoyId: string | undefined;
	className?: string;
}

export const BuoyPairAlarmTable = (props: Props) => {
	const { buoyId, className } = props;
	const classes = useStyles();
	const history = useHistory();

	const getPage = useCallback((pagingOpts, sortOpts) => {
		if (!buoyId) {
			return Promise.resolve({ data: [], total: 0 });
		}
		return buoyApi.findPairedBuoys(buoyId, pagingOpts, sortOpts ? { [sortOpts.sortBy]: sortOpts.sortDir } : undefined);
	}, [buoyId]);

	if (!buoyId) return null;

	return (
		<div className={classes.container}>
			<div className={classes.tableContainer}>
				<Table fetchTablePageFn={getPage}
					className={className}
					options={{
						title: 'Uparene bove',
						defaultPageSize: 25,
						rowSelectionProperty: 'buoyPairAlarmDefId',
					}}
					toolbarActions={<TableToolbar buoyId={buoyId} />}
				>
					<RowSelectionColumn />
					<Column field='buoy.name' label='Ime' />
					<Column field='buoy.deviceKey' label='DevEUI' />
					<Column field='distanceThreshold' label='Maksimalna udaljenost' />
					<Column width={'5%'}>
						{(row: PairedBuoyDetails, _isRowSelected, _toggleRowSelection, _refreshPage) => {
							return (
								<div style={{
									display: 'flex',
									justifyContent: 'flex-end'
								}}>
									<Tooltip title='Prikaži'>
										<IconButton onClick={_ => {
											history.push(getBuoyShowRoute(row.buoy.id));
										}}>
											<Visibility />
										</IconButton>
									</Tooltip>
								</div>
							);
						}}
					</Column>
				</Table>
			</div>
		</div>
	);
};