import Axios from 'axios';
import { IsArray, IsBoolean, IsNumber, IsOptional, IsUUID, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { createFilterBuilder, Filter, toQueryParams, Paging, Page, Sorting } from './reusable/filters';
import { RestCrudApi } from './reusable/generic-rest-crud';
import { authService } from './auth.api';
import { BuoyConfigurationMsg, BuoyDownlinkMsg, BuoyGpsConfigurationMsg, BuoyMsgType } from './buoy/buoy-downlink';
import { Attribute, AttrUpdateForBuoy } from './attr-def-set.api';
import { Type } from 'class-transformer';

export type BuoyGeolocationPoint = {
	t: Date;
	latitude: number;
	longitude: number;
	acquiredHepe: number;
	acquiredHdop: number;
	timeAcquiringSec: number;
	noSattelites: number;
};
export type BuoyGeolocationHistory = BuoyGeolocationPoint[];

export interface BuoySearchResult {
	buoyId: string;
	buoyName: string;
	buoyDeviceKey: string;
	matchValue: string;
	matchProperty: string;
}

export interface BuoyDeviceAttributes {
	current_mode?: 'tilt_detection' | 'manual';
	message_interval_phase_init?: number;
	message_interval_phase1?: number;
	message_interval_phase2?: number;
	message_interval_phase3?: number;
	message_interval_phase4?: number;
	message_interval_phase5?: number;
	message_interval_phase_standby?: number;
	message_interval_idle?: number;
	max_unsent_log_records?: number;
	target_hdop?: number;
	target_hepe?: number;
	gps_lock_timeout?: number;
	acc_angle_threshold?: number;
	acc_tilt_timeout?: number;
	gps_distance_repetitions?: number;
	gps_extra_time_sec?: number;
	gps_navigation_mode?: 'normal' | 'fitness' | 'aviation' | 'baloon' | 'stationary';
	gps_max_distance?: number;
	gps_operating_mode?: 'max_hdop' | 'max_hepe' | 'no_movement';
}

export enum BuoyStatus {
	Online = 'online',
	Offline = 'offline',
	Standby = 'standby',
}

export interface Buoy {
	id: string;
	name: string;
	deviceId: string;
	deviceKey: string;
	deviceAttributes: BuoyDeviceAttributes;
	latitude?: number;
	longitude?: number;
	batteryPercentage?: number;
	temperature?: number;
	active?: boolean;
	lastMessageAt?: Date;
	lastGeoLocAt?: Date;
	status?: BuoyStatus | null;
	createdAt: Date;
	updatedAt: Date;
	regionId?: string;
	attributeDefinitionSetId?: string;
	//
	geoFenceAlarmEnabled: boolean;
	geoFenceRadiusInMeters: number;
}

export interface BuoyCreate {
	name: string;
	deviceId: string;
	deviceKey: string;
	deviceAttributes: BuoyDeviceAttributes;
	latitude?: number;
	longitude?: number;
	batteryPercentage?: number;
	temperature?: number;
	active?: boolean;
	lastMessageAt?: Date;
	status?: BuoyStatus;
	regionId?: string | null;
	viewOnlyUserIds?: string[];
	//
	geoFenceAlarmEnabled?: boolean;
	geoFenceRadiusInMeters?: number;
}

export interface BuoyDownlinkAuditLog {
		id: string;
		sentAt: Date;
		sentBy: string;
		buoyId: string;
		sentMsg: BuoyDownlinkMsg;
}

export class ApiBuoyUpdate {
	@IsUUID()
	id!: string;

	@IsUUID()
	@IsOptional()
	attributeDefinitionSetId?: string;
	
	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => AttrUpdateForBuoy)
	@IsOptional()
	attributes!: AttrUpdateForBuoy[];

	@IsBoolean()
	@IsOptional()
	geoFenceAlarmEnabled?: boolean;

	@IsNumber()
	@IsOptional()
	geoFenceRadiusInMeters?: number;
}

export interface PairedBuoyDetails {
	buoyPairAlarmDefId: string;
	buoy: Buoy;
	distanceThreshold: number;
}

export interface BuoyPairAlarmDefCreate {
	fstBuoyId: string;
	sndBuoyId: string;
	distanceThreshold: number;
}

declare const BUOY_FILTER_TAG: unique symbol;
export type BuoyFilter = Filter & { readonly [BUOY_FILTER_TAG]: 'BUOY_FILTER' };

export enum BuoyFilterName {
	Id = 'id',
	Name = 'name',
	RegionId = 'regionId',
	Latitude = 'latitude',
	Longitude = 'latitude',
	DeviceId = 'deviceId',
	DeviceKey = 'deviceKey',
	Status = 'status',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt',
	// one to many
	ViewOnlyUserId = 'viewOnlyUserId',
}

export const Id = createFilterBuilder<string, BuoyFilter>(BuoyFilterName.Id);
export const Name = createFilterBuilder<string, BuoyFilter>(BuoyFilterName.Name);
export const RegionId = createFilterBuilder<string | null, BuoyFilter>(BuoyFilterName.RegionId);
export const Latitude = createFilterBuilder<number | null, BuoyFilter>(BuoyFilterName.Longitude);
export const Longitude = createFilterBuilder<number | null, BuoyFilter>(BuoyFilterName.Longitude);
export const DeviceId = createFilterBuilder<string | null, BuoyFilter>(BuoyFilterName.DeviceId);
export const DeviceKey = createFilterBuilder<string | null, BuoyFilter>(BuoyFilterName.DeviceKey);
export const Status = createFilterBuilder<BuoyStatus | null, BuoyFilter>(BuoyFilterName.Status);
export const CreatedAt = createFilterBuilder<Date | null, BuoyFilter>(BuoyFilterName.CreatedAt);
export const UpdatedAt = createFilterBuilder<Date | null, BuoyFilter>(BuoyFilterName.UpdatedAt);
export const ViewOnlyUserId = createFilterBuilder<string | null, BuoyFilter>(BuoyFilterName.ViewOnlyUserId);

export const transformFromBuoyDto = (dto: any): Buoy => {
	return {
		...dto,
		lastMessageAt: new Date(dto.lastMessageAt),
		lastGeoLocAt: new Date(dto.lastGeoLocAt),
		createdAt: new Date(dto.createdAt),
		updatedAt: new Date(dto.updatedAt),
	};
};

class BuoyApi extends RestCrudApi<BuoyFilter, Buoy, BuoyCreate, ApiBuoyUpdate> {
	constructor() {
		super('/api/buoys2', authService, transformFromBuoyDto);
	}

	getAttributes(buoyId: string): Promise<Attribute[]> {
		const url = `${this.baseUrl}/${buoyId}/attributes`;
		return Axios.get(url, this.constructHeaders()).then(res => res.data);
	}

	search(query: string, filter?: BuoyFilter): Promise<BuoySearchResult[]> {
		const filterQPs = filter ? toQueryParams(filter) : '';
		const queryParams = [`query=${query}`, filterQPs].filter(Boolean).join('&');
		const url = `${this.baseUrl}/search?${queryParams}`;
		return Axios.get(url, this.constructHeaders()).then(res => res.data);
	}

	getBuoyTelemetry(buoyId: string, telemetryName: string, from?: Date, to?: Date) {
		const queryParams = [
			from ? `from=${from.toISOString()}` : undefined,
			to ? `to=${to.toISOString()}` : undefined,
		]
			.filter(Boolean)
			.join('&');
		const url = `${this.baseUrl}/${buoyId}/telemetry/${telemetryName}?${queryParams}`;
		return Axios.get(url, this.constructHeaders()).then(res => res.data);
	}

	sendBuoyDownlinkMsg(buoyIdOrIds: string | string[], msg: BuoyDownlinkMsg): Promise<void> {
		const url = `${this.baseUrl}/downlink`;
		return Axios.post(url, {
				buoyIds: Array.isArray(buoyIdOrIds) ? buoyIdOrIds : [buoyIdOrIds],
				msg,
			},
			this.constructHeaders(),
		);
	}

	sendGeoTriggerMsg(buoyIdOrIds: string | string[]): Promise<void> {
		return this.sendBuoyDownlinkMsg(buoyIdOrIds, { type: BuoyMsgType.GeoTrigger });
	}

	sendSoftPowerOffMsg(buoyIdOrIds: string | string[]): Promise<void> {
		return this.sendBuoyDownlinkMsg(buoyIdOrIds, {
			type: BuoyMsgType.Configuration,
			operatingMode: 'manual',
			manualTriggerOn: false
		});
	}

	sendHardPowerOffMsg(buoyIdOrIds: string | string[]): Promise<void> {
		return this.sendBuoyDownlinkMsg(buoyIdOrIds, { type: BuoyMsgType.PowerOff });
	}

	sendChangePasswordMsg(buoyIdOrIds: string | string[], password: string): Promise<void> {
		return this.sendBuoyDownlinkMsg(buoyIdOrIds, { type: BuoyMsgType.ChangePassword, password: password });
	}

	sendBuoyConfigurationMsg(buoyIdOrIds: string | string[], msg: BuoyConfigurationMsg): Promise<void> {
		return this.sendBuoyDownlinkMsg(buoyIdOrIds, msg);
	}

	sendBuoyGpsConfigurationMsg(buoyIdOrIds: string | string[], msg: BuoyGpsConfigurationMsg): Promise<void> {
		return this.sendBuoyDownlinkMsg(buoyIdOrIds, msg);
	}

	async getGeoLocationHistory(buoyId: string, from?: Date, to?: Date): Promise<BuoyGeolocationHistory> {
		const queryParams = [
			from ? `from=${from.toISOString()}` : undefined,
			to ? `to=${to.toISOString()}` : undefined,
		]
			.filter(Boolean)
			.join('&');
		const url = `${this.baseUrl}/${buoyId}/geolocation-history?${queryParams}`;
		const { data } = await Axios.get(url, this.constructHeaders());
		return data;
	}

	async getGeoLocationHistoryPaginated(buoyId: string, from?: Date, to?: Date, pagination?: Paging): Promise<Page<BuoyGeolocationPoint>> {
		const queryParams = [
			from ? `from=${from.toISOString()}` : undefined,
			to ? `to=${to.toISOString()}` : undefined,
			pagination ? `page=${pagination.page}&size=${pagination.size}` : undefined,
		]
			.filter(Boolean)
			.join('&');
		const url = `${this.baseUrl}/${buoyId}/geolocation-history-paginated?${queryParams}`;
		const { data: { data, total } } = await Axios.get(url, this.constructHeaders());
		return {
			data: data.map(({ t, ...rest }: { t: Date, latitude: number; longitude: number }) => ({ t: new Date(t), ...rest })),
			total
		};
	}

	async getLatestGeoLocationHistory(buoyIds: string[], to?: Date | null): Promise<Record<string, BuoyGeolocationPoint>> {
		const queryParams = [
			to ? `to=${to.toISOString()}` : undefined,
		]
			.filter(Boolean)
			.join('&');
		const url = `${this.baseUrl}/latest-geolocation-history?${queryParams}`;
		const { data } = await Axios.post(url, buoyIds, this.constructHeaders());
		console.log('getLatestGeoLocationHistory', data);
		return data;
		}
		
		async getBuoyDownlinkAuditLogs(buoyId: string, from?: Date, to?: Date, pagination?: Paging): Promise<Page<BuoyDownlinkAuditLog>> {
		const queryParams = [
			from ? `from=${from.toISOString()}` : undefined,
			to ? `to=${to.toISOString()}` : undefined,
			pagination ? `page=${pagination.page}&size=${pagination.size}` : undefined,
		]
			.filter(Boolean)
			.join('&');
		const url = `${this.baseUrl}/${buoyId}/downlink-audit-log?${queryParams}`;
				const { data: { data, total } } = await Axios.get(url, this.constructHeaders());
		return {
			data: data.map(({ sentAt, ...rest }: { sentAt: Date }) => ({ sentAt: new Date(sentAt), ...rest })),
			total
		};
	}

	findPairedBuoys(id: string, paging?: Paging, sorting?: Sorting): Promise<Page<PairedBuoyDetails>> {
		const pagingQPs = paging ? `page=${paging.page}&size=${paging.size}` : '';
		let sortingQP;
		if (sorting) {
			const sortKey = Object.keys(sorting)[0];
			const sortVal = sorting[sortKey] === 'ASC' ? `asc:${sortKey}` : `desc:${sortKey}`;
			sortingQP = `sort=${sortVal}`;
		}
		const queryParams = [pagingQPs, sortingQP].filter(Boolean).join('&');
		return Axios.get<Page<PairedBuoyDetails>>(`/api/buoys/${id}/paired-buoys?${queryParams}`, this.constructHeaders())
			.then(data => data.data);
	}

	pairBuoy(create: BuoyPairAlarmDefCreate): Promise<unknown> {
		const url = '/api/buoy-pair-alarm-defs';
		return Axios.post(
			url,
			create,
			this.constructHeaders(),
		);
	}

	unpairBuoys(ids: string[]): Promise<void> {
		return Axios.delete('/api/buoy-pair-alarm-defs/remove', { ...this.constructHeaders(), data: ids })
			.then(data => data.data);			
	}
}

export const buoyApi = new BuoyApi();
