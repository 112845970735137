
import React from 'react';
import { Dialog, DialogTitle, Button, makeStyles, DialogActions, DialogContent, Switch, FormControlLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Buoy, buoyApi } from '../../../../../api/buoy.api';
import { useConfirmModal } from '../../../../../contexts/confirm-modal/ConfirmModalContext';

const useStyles = makeStyles(() => {
	return {

	};
});

interface Props {
	buoys: Buoy[];
	open: boolean;
	onClose?: () => void;
}

export const BuoyPowerOffMsgDialog = (props: Props) => {
	const { open, onClose, buoys } = props;
	const { enqueueSnackbar } = useSnackbar();
	const promptConfirm = useConfirmModal();
	const classes = useStyles();
	const [hardPowerOff, setHardPowerOff] = React.useState(false);
	const multipleBuoys = buoys.length > 1;
	const { title, message, no, yes } = {
		title: `Isključivanje ${multipleBuoys ? 'bova' : 'bove'}`,
		message: `Jeste li sigurni da želite isključiti ${multipleBuoys ? 'bove' : `bovu`}?`,
		no: 'Ne',
		yes: 'Da'
	};

	return (
		<Dialog
			open={open}
			onClose={() => onClose && onClose()}
		>
			<div>
				<DialogTitle>
					<span style={{ fontWeight: 800 }}>{title}</span>
				</DialogTitle>

				<DialogContent>
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}>
						<div style={{ marginBottom: '15px' }}>
							{message}
						</div>
						<FormControlLabel
							label={`Trajno isključivanje ${multipleBuoys ? 'bova' : 'bove'}`}
							control={<Switch value={hardPowerOff} onChange={e => setHardPowerOff(Boolean(e.target.checked))} />}
						/>
					</div>

				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => onClose && onClose()}
						variant='contained'>
						{no}
					</Button>
					<Button
						onClick={async () => {
							if (!hardPowerOff) {
								try {
									await buoyApi.sendSoftPowerOffMsg(props.buoys.map(b => b.id));
									enqueueSnackbar(`Zahtjev za isključivanje ${multipleBuoys ? 'bova' : `bove`} poslan.`, { variant: 'success' });
								} catch (error) {
									enqueueSnackbar(`Zahtjev za isključivanje ${multipleBuoys ? 'bova' : `bove`} je neuspješan.`, { variant: 'error' });
								}
								return onClose && onClose();
							}
							// confirm hard power off one more time
							const msg = `Jeste li sigurni da želite trajno isključiti ${multipleBuoys ? 'bove' : `bovu`}? Za ponovno uključivanje ${multipleBuoys ? 'bova' : `bove`} biti će potreban fizički pristup ${multipleBuoys ? 'bovama' : `bovi`}.`;
							const yes = await promptConfirm({ message: msg, no: 'Ne', yes: 'Da' });
							if (yes) {
								try {
									await buoyApi.sendHardPowerOffMsg(props.buoys.map(b => b.id));
									enqueueSnackbar(`Zahtjev za trajno isključivanje ${multipleBuoys ? 'bova' : `bove`} poslan.`, { variant: 'success' });
								} catch (error) {
									enqueueSnackbar(`Zahtjev za trajno isključivanje ${multipleBuoys ? 'bova' : `bove`} je neuspješan.`, { variant: 'error' });
								}
								onClose && onClose();
							}
						}}
						variant='contained'
						color='primary'>
						{yes}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};
