import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { BuoyDeviceAttributes } from '../../../../../api/buoy.api';
import { BuoyGpsConfigurationMsg } from '../../../../../api/buoy/buoy-downlink';
import { BuoyGpsSettingsFormModel, toBuoyGpsSettingsForm, toBuoyGpsConfigurationMsg, buoyGpsSettingsValidationSchema } from './BuoyGpsSettings.type';
import { BuoyGpsSettingsForm } from './BuoyGpsSettingsForm';

interface Props {
	onSend: (msg: BuoyGpsConfigurationMsg) => void;
	onCancel?: () => void;
	buoyDeviceAttributes?: BuoyDeviceAttributes;
}

const useStyles = makeStyles({
	input: {
		width: '300px',
	},
});

export const BuoyGpsSettings = (props: Props) => {
	const { onSend, onCancel, buoyDeviceAttributes } = props;
	const classes = useStyles();

	const formik = useFormik<BuoyGpsSettingsFormModel>({
		initialValues: buoyDeviceAttributes ? toBuoyGpsSettingsForm(buoyDeviceAttributes) : {},
		onSubmit: (form: BuoyGpsSettingsFormModel) => {
			onSend(toBuoyGpsConfigurationMsg(form));
		},
		validationSchema: buoyGpsSettingsValidationSchema,
	});
	
	return (
		<div>
			<BuoyGpsSettingsForm formik={formik} />
			<div style={{ marginTop: '30px'}}>
				<Button
					style={{ margin: '10px 0 16px 0' }}
					onClick={() => formik.handleSubmit() }
					variant='contained'
					color='primary'>
					Primjeni
				</Button>
				<Button
					style={{ margin: '10px 0 16px 10px' }}
					onClick={() => onCancel ? onCancel() : null}
					variant='contained'>
					Otkaži
				</Button>
			</div>
		</div>
	);
};
