import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { PinDrop, PowerSettingsNew, Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { BuoyConfigureDialog } from '../../views/buoys/buoy-device-message/BuoyConfigureDialog';
import { BuoyPowerOffMsgDialog } from '../../views/buoys/buoy-device-message/power-off-message/BuoyPowerOffMsgDialog';
import { useUserRole } from '../../utility/useUserRole';
import { useConfirmModal } from '../../../contexts/confirm-modal/ConfirmModalContext';
import { Buoy, buoyApi } from '../../../api/buoy.api';

const useBuoyDownlinkButtonsStyles = makeStyles(() => {
	return {
		configButtons: {
			'& > *': {
				marginLeft: '15px'
			},
		}
	};
});

interface Props {
	buoys: Buoy | Buoy[];
}

export const BuoyDownlinkButtons = (props: Props) => {
	const buoys = Array.isArray(props.buoys) ? props.buoys : [props.buoys];
	const classes = useBuoyDownlinkButtonsStyles();
	const { enqueueSnackbar } = useSnackbar();
	const promptConfirm = useConfirmModal();
	const { isSuperAdmin, isAdmin, isRegionAdmin } = useUserRole();
	const canSendBuoyMsgs = isSuperAdmin || isAdmin || isRegionAdmin;
	const canPowerOff = isSuperAdmin || isAdmin;
	const [configureDialogOpen, setConfigureDialogOpen] = React.useState(false);
	const [powerOffDialogOpen, setPowerOffDialogOpen] = React.useState(false);

	if (!canSendBuoyMsgs) return <></>;
	return (
		<>
			<BuoyConfigureDialog
				buoys={buoys}
				open={configureDialogOpen}
				onClose={() => setConfigureDialogOpen(false)}
			/>
			<BuoyPowerOffMsgDialog
				buoys={buoys}
				open={powerOffDialogOpen}
				onClose={() => setPowerOffDialogOpen(false)}
			/>
			<div className={classes.configButtons}>
				<Tooltip title={'Konfiguracija'}>
					<IconButton onClick={_ => setConfigureDialogOpen(true)}>
						<Settings color='primary' />
					</IconButton>
				</Tooltip>
				<Tooltip title={'Geolociranje'}>
					<IconButton onClick={async _ => {
						const yes = await promptConfirm({ message: `Jeste li sigurni da želite geolocirati ${buoys.length > 1 ? 'bove' : `bovu`}?`, no: 'Ne', yes: 'Da' });
						if (!yes) return;
						try {
							await buoyApi.sendGeoTriggerMsg(buoys.map(b => b.id));
							enqueueSnackbar(`Zahtjev za geolociranje ${buoys.length > 1 ? 'bova' : `bove`} poslan.`, { variant: 'success' });
						} catch (error) {
							enqueueSnackbar(`Zahtjev za geolociranje ${buoys.length > 1 ? 'bova' : `bove`} je neuspješan.`, { variant: 'error' });
						}
					}}>
						<PinDrop color='primary' />
					</IconButton>
				</Tooltip>
				{
					canPowerOff && <Tooltip title={'Isključivanje'}>
						<IconButton onClick={async _ => setPowerOffDialogOpen(true)}>
							<PowerSettingsNew color='primary' />
						</IconButton>
					</Tooltip>
				}
			</div>
		</>
	);
};
