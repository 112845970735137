// in firefox: type number on input allows text to be input..., this prevents it
export const firefoxInputIntegerKeypressHack = (evt: any) => {
	if (evt.which === 8 || evt.which === 45) { // backspace and minus allowed
		return;
	}
	if (evt.which < 48 || evt.which > 57) { // only numerals allowed
		evt.preventDefault();
	}
};

export const firefoxInputRealKeypressHack = (evt: any) => {
	if (evt.which === 8 || evt.which === 46 || evt.which === 45) { // backspace, decimal dot and minus allowed
		return;
	}
	if (evt.which < 48 || evt.which > 57) { // only numerals allowed
		evt.preventDefault();
	}
};
