import React from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Delete, DoubleArrow } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { GenericRequestErrorMsg } from './utility/general-messages';
import { getBuoyShowRoute } from './views/buoys/BuoyRouter';
import { useHistory } from 'react-router-dom';
import { MapAction, MapState } from './views/buoy-tracker-map/map-state';
import { Buoy, buoyApi } from '../api/buoy.api';
import { LabeledText } from './reusable/LabeledText';
import { BuoyDownlinkButtons } from './views/buoys/BuoyDownlinkButtons';

const useBuoyDetailsStyles = makeStyles({
	container: {
		position: 'relative',
		padding: '20px 15px 15px 15px',
		maxHeight: '100%',
		overflowY: 'auto',
	},
	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%'
	},
	info: {
		paddingLeft: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			marginRight: 40
		}
	},
	chartContainer: {
		height: '400px',
		marginTop: '50px'
	},
	lastTHoursSelect: {
		width: '200px'
	}
});

interface Props {
	ids: string[];
	mapState: MapState;
	mapDispatch: React.Dispatch<MapAction>;
	closeFn: () => void;
}

export const MultipleBuoysDetails = (props: Props) => {
	const { ids } = props;
	const classes = useBuoyDetailsStyles();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [buoys, setBuoys] = React.useState<Buoy[]>([]);

	React.useEffect(() => {
		if (!ids || !ids.length) return;
		setBuoys([]);
		buoyApi.getMany(ids)
			.then(buoys => setBuoys(buoys))
			.catch(err => {
				console.log('error:', err);
				enqueueSnackbar(GenericRequestErrorMsg('bova'), { variant: 'error' });
			});
	}, [ids]);

	return (
		<div className={classes.container}>
			{
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
					<div>Broj odabranih bova: {buoys.length}</div>
					{buoys && <BuoyDownlinkButtons buoys={buoys} />}
				</div>
			}
			{
				buoys?.map((buoy: Buoy, idx) => {
					return (
						<div style={{
							display: 'flex',
							flexDirection: 'column'
						}}>
							<div style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginTop: '35px',
							}}
								key={buoy.id}>
								<LabeledText label='Ime' value={buoy.name} />
								<LabeledText label='Vrijeme zadnje poruke' value={buoy?.lastMessageAt?.toLocaleString('hr') || '/'} />
							</div>
							<div style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
								key={`actions-${buoy.id}`}>
								<Button
									onClick={_ => history.push(getBuoyShowRoute(buoy.id))}
									variant="contained"
									color="primary"
									endIcon={<DoubleArrow />}>
									Na detaljniji prikaz
									</Button>
								<Tooltip title='Makni s popisa'>
									<IconButton
										onClick={() => {
											const rest = [...buoys.slice(0, idx), ...buoys.slice(idx + 1)];
											if (rest.length === 0) props.closeFn();
											setBuoys(rest);
										}}
									>
										<Delete color="primary" style={{ fontSize: 24 }} />
									</IconButton>
								</Tooltip>
							</div>

						</div>
					);
				})
			}
		</div>
	);
};