
import { GenericAuthService, MandatoryTokenDetails } from './reusable/generic-auth-service';
import { UserRole } from '../model/user';
import { enumValuesToArray } from '../helpers';


interface JWTDetails extends MandatoryTokenDetails {
	id: string;
	email: string;
	createdAt: string;
	role: UserRole;
	iat: number;
	exp: number;
}

class AuthService extends GenericAuthService<JWTDetails> {
	constructor() {
		super('/api/login');
	}

	getSubSetUserRoles(): UserRole[] {
		switch (this.getUserDetails()?.role) {
			case UserRole.SuperAdmin: return enumValuesToArray(UserRole) as UserRole[];
			case UserRole.Admin: return [
				UserRole.Admin, UserRole.RegionAdmin, UserRole.ViewOnlyUser
			];
			case UserRole.RegionAdmin: return [
				UserRole.RegionAdmin, UserRole.ViewOnlyUser
			];
			case UserRole.ViewOnlyUser: return [
				UserRole.ViewOnlyUser
			];
			default: return [];
		}
	}

	private readonly UserRoleOrdering: any[] = [
		UserRole.ViewOnlyUser,
		UserRole.RegionAdmin,
		UserRole.Admin,
		UserRole.SuperAdmin
	]

	private isSubRole(anotherUserRole: UserRole) {
		const currentUserRole = authService.getUserDetails()?.role;
		return this.UserRoleOrdering.indexOf(currentUserRole) < this.UserRoleOrdering.indexOf(anotherUserRole);
	}

	hasUserWriteRights(anotherUserRole: UserRole) {
		const userWriteRights = [UserRole.SuperAdmin, UserRole.Admin].some(role => role === this.getUserDetails()?.role);
		const isSubRole = this.isSubRole(anotherUserRole);
		return userWriteRights && !isSubRole;
	}
}

export const authService = new AuthService();
