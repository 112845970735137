import { authService } from '../../api/auth.api';
import { UserRole } from '../../api/user.api';

export const useUserRole = () => {
    const role: UserRole | undefined = authService.getUserDetails()?.role;

    return {
        isSuperAdmin: role === UserRole.SuperAdmin,
        isAdmin: role === UserRole.Admin,
        isRegionAdmin: role === UserRole.RegionAdmin,
        isViewOnlyUser: role === UserRole.ViewOnlyUser,
    };
};
