import { useFormik } from 'formik';
import { BuoyDeviceAttributes } from '../../../../../api/buoy.api';
import { BuoyConfigurationMsg, BuoyMsgType } from '../../../../../api/buoy/buoy-downlink';
import * as Yup from 'yup';

export const FormikTypeHack = () => {
	return useFormik<BuoyGeneralSettingsFormModel>({} as any);
};

export interface BuoyGeneralSettingsFormModel {
	msgIntervalPhaseInit?: number;
	msgIntervalPhase1?: number;
	msgIntervalPhase2?: number;
	msgIntervalPhase3?: number;
	msgIntervalPhase4?: number;
	msgIntervalPhase5?: number;
	msgIntervalPhaseStandby?: number;
	msgIntervalIdle?: number;
	maxUnsentLogs?: number;

	operatingMode?: 'manual' | 'tilt_detection';

	// tilt detection relevant
	accAngleThreshold?: number;
	accTiltTimeout?: string;

	// manual relevant
	manualTriggerOn?: boolean;
}

export const toBuoyGeneralSettingsForm = (attributes?: BuoyDeviceAttributes): BuoyGeneralSettingsFormModel => {
	const acc_tilt_timeout = attributes?.acc_tilt_timeout;
	return {
		msgIntervalPhaseInit: attributes?.message_interval_phase_init,
		msgIntervalPhase1: attributes?.message_interval_phase1,
		msgIntervalPhase2: attributes?.message_interval_phase2,
		msgIntervalPhase3: attributes?.message_interval_phase3,
		msgIntervalPhase4: attributes?.message_interval_phase4,
		msgIntervalPhase5: attributes?.message_interval_phase5,
		msgIntervalPhaseStandby: attributes?.message_interval_phase_standby,
		msgIntervalIdle: attributes?.message_interval_idle,
		maxUnsentLogs: attributes?.max_unsent_log_records,
		accAngleThreshold: attributes?.acc_angle_threshold,
		accTiltTimeout: typeof acc_tilt_timeout === 'number' ? String(acc_tilt_timeout) : undefined,
		operatingMode: attributes?.current_mode
	};
};

export enum TogglebleSettingName {
	MsgIntervalPhaseInit = 'msgIntervalPhaseInit',
	MsgIntervalPhase1 = 'msgIntervalPhase1',
	MsgIntervalPhase2 = 'msgIntervalPhase2',
	MsgIntervalPhase3 = 'msgIntervalPhase3',
	MsgIntervalPhase4 = 'msgIntervalPhase4',
	MsgIntervalPhase5 = 'msgIntervalPhase5',
	MsgIntervalPhaseStandby = 'msgIntervalPhaseStandby',
	MsgIntervalIdle = 'msgIntervalIdle',
	OperatingMode = 'operatingMode',
	MaxUnsentLogs = 'maxUnsentLogs'
}

export const toBuoyConfigurationMsg: (form: BuoyGeneralSettingsFormModel) => BuoyConfigurationMsg = ({
	accAngleThreshold, accTiltTimeout, manualTriggerOn, maxUnsentLogs, msgIntervalIdle, msgIntervalPhase1,
	msgIntervalPhase2, msgIntervalPhase3, msgIntervalPhase4, msgIntervalPhase5, msgIntervalPhaseInit,
	msgIntervalPhaseStandby, operatingMode
}) => {
	// TODO: <AsyncSelect /> (in BuoyGeneralSettingsForm) has problems with int id 0 -> so IDs are now strings thus the conversions below... FIX <AsyncSelect />!
	const accTiltTimeoutAsNum: number | undefined = Number.parseInt(accTiltTimeout || '');
	return {
		type: BuoyMsgType.Configuration,
		accAngleThreshold: operatingMode === 'manual' ? undefined : accAngleThreshold,
		accTiltTimeout: operatingMode === undefined || operatingMode === 'manual' ? undefined : accTiltTimeoutAsNum && accTiltTimeoutAsNum,
		manualTriggerOn: operatingMode === undefined || operatingMode === 'tilt_detection' ? undefined : Boolean(manualTriggerOn),
		maxUnsentLogs,
		msgIntervalIdle,
		msgIntervalPhase1,
		msgIntervalPhase2,
		msgIntervalPhase3,
		msgIntervalPhase4,
		msgIntervalPhase5,
		msgIntervalPhaseInit,
		msgIntervalPhaseStandby,
		operatingMode
	};
};

export const OperatingModeOpts = {
	'Automatski': 'tilt_detection',
	'Ručni': 'manual'
};

export const AccAngleThresholdOpts = {
	'50 °': 50,
	'60 °': 60,
	'70 °': 70,
	'80 °': 80,
	'2x 50 °': 100,
	'2x 60 °': 120,
	'2x 70 °': 140,
	'2x 80 °': 160,
};
export const AccTiltTimeoutOpts = {
	'0 sek': '0',
	'30 sek': '30',
	'60 sek': '60',
	'180 sek': '180',
	'300 sek': '300'
};

export const buoyGeneralSettingsValidationSchema = Yup.object().shape<BuoyGeneralSettingsFormModel>({
	msgIntervalPhaseInit: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(5, 'Može biti minimalno 5')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalPhase1: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(5, 'Može biti minimalno 5')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalPhase2: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(5, 'Može biti minimalno 5')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalPhase3: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(5, 'Može biti minimalno 5')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalPhase4: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(5, 'Može biti minimalno 5')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalPhase5: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(5, 'Može biti minimalno 5')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalPhaseStandby: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(0, 'Može biti minimalno 0')
		.max(65535, 'Može biti maksimalno 65535'),
	msgIntervalIdle: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(0, 'Može biti minimalno 0')
		.max(65535, 'Može biti maksimalno 65535'),
	maxUnsentLogs: Yup.number()
		.typeError('Mora biti cijeli broj')
		.integer('Mora biti cijeli broj')
		.min(0, 'Može biti minimalno 0')
		.max(100, 'Može biti maksimalno 100'),
	operatingMode: Yup.string()
		.oneOf(Object.values(OperatingModeOpts), 'Mora biti tilt_detection ili manual') as any,
	accAngleThreshold: Yup.number()
		.oneOf(Object.values(AccAngleThresholdOpts), `Mora biti jedna od sljedecih vrijednosti: ${AccAngleThresholdOpts}`)
		.when('operatingMode', {
			is: 'tilt_detection',
			then: Yup.number().required('Kutni prag je obavezan.')
		}),
	accTiltTimeout: Yup.string()
		.oneOf(Object.values(AccTiltTimeoutOpts), `Mora biti jedna od sljedecih vrijednosti: ${AccTiltTimeoutOpts}`)
		.when('operatingMode', {
			is: 'tilt_detection',
			then: Yup.string().required('Timeout je obavezan.')
		}),
	manualTriggerOn: Yup.boolean().optional(),
});
