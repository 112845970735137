import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles, DialogActions, Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { subMonths, startOfDay, endOfDay } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import hrLocale from 'date-fns/locale/hr';
import { AsyncSelect } from '@telemetris/ui-components';
import { authService } from '../../api/auth.api';

const useStyles = makeStyles(_theme => {
    return {
        dialog: {
            overflow: 'visible',

            '& .MuiDialog-paper': {
                overflow: 'visible',
            }
        },
        content: {
            overflow: 'visible',
        },
        dateTimePickerDark: {
            color: 'white',

            '& .react-datetime-picker__wrapper': {
                borderColor: 'white',
            },
            '& .react-datetime-picker__inputGroup__input': {
                color: 'white',
            },
            '& .react-datetime-picker__button > svg': {
                stroke: 'white',
            },
        },
    };
});

interface Props {
	id: string;
	className?: string;
}

export const ExportBuoyButton = (props: Props) => {
	const { id, className } = props;
	const [open, setOpen] = useState<boolean>(false);
	const [fromDate, setFromDate] = useState<Date | null>(startOfDay(subMonths(new Date(), 1)));
	const [toDate, setToDate] = useState<Date | null>(endOfDay(new Date()));
	const [format, setFormat] = useState<'xlsx' | 'gpx'>('xlsx');
	const classes = useStyles();

	return (
		<>
			<Button
				className={className}
				color='primary'
				size='small'
				onClick={() => setOpen(true)}
			>
				<ExportIcon /> Izvezi
			</Button>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				className={classes.dialog}
			>
				<DialogTitle id='form-dialog-title'>Izvoz</DialogTitle>
				<DialogContent className={classes.content}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
					<DatePicker
						label='Od'
						value={fromDate}
						onChange={(newDate: Date | null) => setFromDate(newDate && startOfDay(newDate))}
					/>
					<DatePicker
						label='Do'
						value={toDate}
						onChange={(newDate: Date | null) => setFromDate(newDate && endOfDay(newDate))}
					/>
				</MuiPickersUtilsProvider>
				<AsyncSelect
					style={{display: 'block'}}
					label='Format'
					getOptions={() => Promise.resolve([
						{ id: 'xlsx', name: 'Excel' },
						{ id: 'gpx', name: 'GPX' },
					])}
					value={format}
					onChange={(value: string | null) => setFormat(value as 'xlsx' | 'gpx')}
				/>
				</DialogContent>
				<DialogActions>
					<Button
						size='large'
						onClick={() => setOpen(false)}
					>
						Odustani
					</Button>
				<a href={`/api/export/buoy/${id}/${format}?accessToken=${authService.getAccessToken()}&fromTime=${fromDate?.toISOString()}&toTime=${toDate?.toISOString()}`}
					style={{textDecoration: 'none'}}
					target='_blank' rel="noreferrer">
					<Button
						color='primary'
						size='large'
						onClick={() => {
							setOpen(false);
						}}
					>
						<ExportIcon /> Izvezi
					</Button>
				</a>
				</DialogActions>
			</Dialog>
		</>
	);
};
