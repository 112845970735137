import { BuoyConfigurationMsg, BuoyDownlinkMsg, BuoyGpsConfigurationMsg, BuoyMsgType } from '../../../../api/buoy/buoy-downlink';
import { BuoyConfigurationMsgTranslation, BuoyConfigurationMsg_OperatingMode_Translation, BuoyGpsConfigurationMsgTranslation, BuoyGpsConfigurationMsg_GpsNavigationMode_Translation, BuoyGpsConfigurationMsg_OperatingMode_Translation, BuoyMsgTypeTranslation } from '../buoy.translations';

// helper functions for displaying downlink msg audit details

export const getBuoyDownlinkMsgDetails = (msg: BuoyDownlinkMsg) => {
	switch (msg.type) {
		case BuoyMsgType.Configuration: return getBuoyConfigurationMsgDetails(msg);
		case BuoyMsgType.GpsConfiguration: return getBuoyGpsConfigurationMsgDetails(msg);
		default: return [];
	}
};

const getMsgIntervalPhaseLabel = (value: number) => `${value} min`;

export const getBuoyConfigurationMsgDetails = (msg: BuoyConfigurationMsg): [string, string][] => {
	const details = [
		[BuoyConfigurationMsgTranslation.type, BuoyMsgTypeTranslation.configuration],
		msg.msgIntervalPhaseInit !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhaseInit, getMsgIntervalPhaseLabel(msg.msgIntervalPhaseInit)],
		msg.msgIntervalPhase1 !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhase1, getMsgIntervalPhaseLabel(msg.msgIntervalPhase1)],
		msg.msgIntervalPhase2 !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhase2, getMsgIntervalPhaseLabel(msg.msgIntervalPhase2)],
		msg.msgIntervalPhase3 !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhase3, getMsgIntervalPhaseLabel(msg.msgIntervalPhase3)],
		msg.msgIntervalPhase4 !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhase4, getMsgIntervalPhaseLabel(msg.msgIntervalPhase4)],
		msg.msgIntervalPhase5 !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhase5, getMsgIntervalPhaseLabel(msg.msgIntervalPhase5)],
		msg.msgIntervalPhaseStandby !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalPhaseStandby, getMsgIntervalPhaseLabel(msg.msgIntervalPhaseStandby)],
		msg.msgIntervalIdle !== undefined && [BuoyConfigurationMsgTranslation.msgIntervalIdle, getMsgIntervalPhaseLabel(msg.msgIntervalIdle)],
		msg.maxUnsentLogs !== undefined && [BuoyConfigurationMsgTranslation.maxUnsentLogs, msg.maxUnsentLogs + ''],
		msg.operatingMode !== undefined && [BuoyConfigurationMsgTranslation.operatingMode, BuoyConfigurationMsg_OperatingMode_Translation[msg.operatingMode]],
		// tilt detection
		msg.accAngleThreshold !== undefined && [BuoyConfigurationMsgTranslation.accAngleThreshold, `${msg.accAngleThreshold}°`],
		msg.accTiltTimeout !== undefined && [BuoyConfigurationMsgTranslation.accTiltTimeout, `${msg.accTiltTimeout} sek`],
		// manual
		msg.manualTriggerOn !== undefined && [BuoyConfigurationMsgTranslation.manualTriggerOn, msg.manualTriggerOn ? 'da' : 'ne'],
	].filter(Boolean);

	return details as [string, string][];
};

export const getBuoyGpsConfigurationMsgDetails = (msg: BuoyGpsConfigurationMsg): [string, string][] => {
	const details = [
		[BuoyConfigurationMsgTranslation.type, BuoyMsgTypeTranslation.configuration],
		msg.operatingMode !== undefined && [BuoyGpsConfigurationMsgTranslation.operatingMode, BuoyGpsConfigurationMsg_OperatingMode_Translation[msg.operatingMode]],
		msg.lockTimeout !== undefined && [BuoyGpsConfigurationMsgTranslation.lockTimeout, `${msg.lockTimeout} sek`],
		msg.gpsNavigationMode !== undefined && [BuoyGpsConfigurationMsgTranslation.gpsNavigationMode, BuoyGpsConfigurationMsg_GpsNavigationMode_Translation[msg.gpsNavigationMode]],
		// max hepe
		msg.targetHEPE !== undefined && [BuoyGpsConfigurationMsgTranslation.targetHEPE, `${msg.targetHEPE}`],
		// max hdop
		msg.targetHDOP !== undefined && [BuoyGpsConfigurationMsgTranslation.targetHDOP, `${msg.targetHDOP}`],
		msg.extraTime !== undefined && [BuoyGpsConfigurationMsgTranslation.extraTime, `${msg.extraTime} sek`],
		// stationary
		msg.maxDistance !== undefined && [BuoyGpsConfigurationMsgTranslation.maxDistance, `${msg.maxDistance} m`],
		msg.distanceRepetitions !== undefined && [BuoyGpsConfigurationMsgTranslation.distanceRepetitions, `${msg.distanceRepetitions} sek`],
	].filter(Boolean);

	return details as [string, string][];
};
