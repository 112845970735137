import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AlarmList } from './AlarmList';

export const AlarmBaseRoute = '/alarms';
export const getAlarmListRoute = () => AlarmBaseRoute;

export const AlarmRouter = () => {
    const match = useRouteMatch();

    return <Switch>
        <Route exact path={`${match.path}/`}>
            <AlarmList />
        </Route>
    </Switch>;
};
