import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { BuoyList } from './BuoyList';
import { BuoyShow } from './BuoyShow';
// import { BuoyEdit } from '../../views/buoys/BuoyEdit';

export const getBuoyListRoute = () => '/buoys';
export const getBuoyCreateRoute = () => '/buoys/new';
export const getBuoyShowRoute = (id: string) => `/buoys/${id}/show`;


export const BuoyRouter = () => {
    const match = useRouteMatch();

    return <Switch>
        {/* <Route path={`${match.path}/new`}>
            <BuoyCreate />
        </Route> */}
        <Route path={`${match.path}/:id/show`}>
            <BuoyShow />
        </Route>
        <Route exact path={`${match.path}/`}>
            <BuoyList />
        </Route>
    </Switch>;
};