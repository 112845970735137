import React from 'react';
import { Dialog, Table, TableBody, TableContainer, TableRow, TableCell, Typography, Paper, makeStyles } from '@material-ui/core';
import { getBuoyDownlinkMsgDetails } from './message-details';
import { BuoyDownlinkMsg } from '../../../../api/buoy/buoy-downlink';

interface Props {
	message: BuoyDownlinkMsg | undefined;
	open: boolean;
	onClose?: () => void;
}

const useStyles = makeStyles({
	table: {
		minWidth: 300,
	},
});

export const BuoyDownlinkAuditLogMsgDialog = (props: Props) => {
	const { message, open, onClose } = props;
	const classes = useStyles();
	const details = props.message && getBuoyDownlinkMsgDetails(props.message);

	return (
		<Dialog
			onClose={() => {
				onClose && onClose();
			}}
			open={open}
		>
			<TableContainer elevation={0} component={Paper} style={{ marginTop: '15px', marginBottom: '15px' }}>
				<Typography style={{ margin: '0px 0px 16px 10px' }} variant="h6" id="dialogTitle" component="div">
					Detalji poruke
					</Typography>
				<Table className={classes.table} size="small" aria-label="a dense table">
					<TableBody>
						{
							details?.map(([label, value]: [string, string]) => (
								<TableRow key={`${label}-${value}`}>
									<TableCell component="th" scope="row">{label}</TableCell>
									<TableCell align="right">{value}</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Dialog>
	);
};
